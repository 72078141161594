.turn-card-body {
  position: relative;
  z-index: 0;
}

.section {
  margin-bottom: 20px;
  margin-left: 25px;
}

.turn-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.label {
  width: 70%;
  text-align: left;
  font-size: 14px;
}

.value {
  width: 30%;
  text-align: right;
  font-size: 14px;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}
strong {
  font-weight: bold;
}

p {
  font-size: 14px;
  line-height: 1.6;
}