.products-card{
  margin: 15px;
  padding: 10px;
  border: solid;
  border-width: 2px;
  border-radius: 25px;
  width: auto;
  //height: auto;
  min-height: 150px;
  max-height: 400px;
  overflow-y: scroll;
}