.container-permission{
  display:flex;
}

.content-permission{
  width: 50%;
  max-height: 500px;
}

@media (min-width: 600px) {
  .content-permission{
    width: 85%;
  }
}

.scroll{
  width: 100%;
  overflow: scroll;
  max-height: 500px;
}

.fixed{
  width:100px;
  border-spacing: 0;
}

.fixed .Thead .Tr{
  background-color:#1b4060;
  height: 80px !important;
  color: whitesmoke;
}

.scrollable .Thead .Tr{
  background-color:#1b4060;
  height: 80px !important;
  color: whitesmoke;
}

.scrollable .Thead{
  background-color:#1b4060;
}

.scrollable{
  width:100px;
  border-spacing: 0;
}

.Td{
  min-width:150px !important;
  height: 82px;
  border: 1px solid #221111;
}

.Th{
  min-width:150px !important;
  height: 100px;
  border: 1px solid #221111;
}

.delete-button{
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #CE5454;
  border: 1px solid #CE5454;
  border-radius: 0px 5px 5px 0px;
}

.accept-button{
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #5cce54;
  border: 1px solid #60ce54;
  border-radius: 0px 5px 5px 0px;
}

.permission{
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  border: 1px solid gray;
  border-radius: 5px;
}
