@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,700);
* { box-sizing: border-box; }


.description {
  padding: 1px;

  h1 {
    color: #515151;
    font-weight: 300;
    padding-top: 15px;
    margin: 0;
    font-size: 20px;
    font-weight: 300;
  }

  h2 {
    color: #515151;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
  }

  h4 {
    margin: 0;
    color: #727272;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px
  }

  p {
    font-size: 12px;
    line-height: 20px;
    color: #727272;
    padding: 3px 0;
    margin: 0;
  }

  button {
    outline: 0;
    border: 0;
    background: none;
    border: 1px solid #d9d9d9;
    padding: 8px 0px;
    margin-bottom: 30px;
    color: #515151;
    text-transform: uppercase;
    width: 125px;
    font-family: inherit;
    margin-right: 5px;
    transition: all 0.3s ease;
    font-weight: 500;

    &:hover {

      // background: darken(white, 2%);
      border: 1px solid #aedaa6;
      color: #aedaa6;
      cursor: pointer;

    }

  }

}

.check {
  float: right;
}

span {
  float: left;
}

.img {
  max-width: 100%;
  height: 100px;
  margin: 5px;
}
