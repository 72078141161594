@import "../../settings/app.colors";

.clients-table-card {
  margin-top: 20px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
}

.cotizap-client-icon{
  max-width: 70px;
  background-color: white;
  border-radius: 30px;
  padding-right: 5px;
}



.card-header {
  background-color: $blue4;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: $white;
  font-size: 14px;
}

.card-body {
  padding: 15px;
}

.form-label {
  color: $blue1;
  font-size: 14px;
  font-weight: bold;
}

.form-input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $blue1;
  padding: 5px 15px;
}

.big-button {
  display: flex;
  justify-content: center;
  background-color: $blue-kolo !important;
  color: white;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  //font-weight: bold;
  font-weight: 500 !important;
  font-size: 14px;

  &:hover {
    transform: scale(1.02, 1.02);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    color: white;
  }
}

.active
{
  background-color: $green !important;
  color : white !important;
}

tr.active {
  &:hover {
    background-color: $blue1 !important;
    color: $white !important;
  }
  &.data.active {
    & > td {
      &:hover {
        background-color: $blue1 !important;
        color: $white !important;
      }
    }
  }

}

.remove-top {
  margin-top: 0 !important;
}
