@import '../../settings/app.colors';

.card {
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
}

.hide-overflow {
  overflow: hidden;
}

.card-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background-color: #226095 !important;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  padding: 12px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}

.ch-white {
  background-color: #ffffff !important;
  color: #2f3542 !important;
}

.button-icon {
  cursor: pointer;
}

.card-body {
  padding: 10px;
}

.corner-ribbon {
  width: 200px;
  background: $red;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1.5px;
  font-weight: bold;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Custom styles */

.corner-ribbon.sticky {
  position: fixed;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

/* Different positions */

.corner-ribbon.top-left {
  top: 15px;
  left: -68px;
  height: 30px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right {
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left {
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right {
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */

.corner-ribbon.white {
  background: #f0f0f0;
  color: #555;
}

.corner-ribbon.black {
  background: $black;
}

.corner-ribbon.grey {
  background: #999;
}

.corner-ribbon.blue {
  background: $blue2;
}

.corner-ribbon.green {
  background: #2c7;
}

.corner-ribbon.red {
  background: $red;
}

.corner-ribbon.orange {
  background: #e82;
}

.corner-ribbon.yellow {
  background: #ec0;
}

.appcard {
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  box-shadow: 1px 1px 5px #0004;
  overflow: hidden;
}

.appcard-header,
.appcard-body {
  position: relative;
  padding: 5px 10px;
}

.appcard-header {
  background-size: 100%;
  background-position: center;
  height: 120px;

  box-shadow: inset 0px -10px 80px #000a;
  transition: box-shadow 1s, background-size 0.8s;
}

.appcard:hover .appcard-header {
  background-size: 110%;
  box-shadow: inset 0px -20px 50px #0008;
}

.appcard-header-title {
  color: white;
  position: absolute;
  bottom: 0;
}

.appcard-header-link {
  position: absolute;
  top: 5px;
  right: 5px;
}

.appcard-body {
  padding-bottom: 60px;
}

.appcard-body > * {
  margin-bottom: 10px;
}

.appcard-body > button {
  margin: 0 auto;
  width: calc(100% - 20px);
  box-sizing: border-box;

  position: absolute;
  bottom: 10px;
}
