@import "../../../settings/app.colors";

.active
{
  background-color: $green !important;
  color : white !important;
}

tr.active {
  &:hover {
    background-color: $blue1 !important;
    color: $white !important;
  }
  &.data.active {
    & > td {
      &:hover {
        background-color: $blue1 !important;
        color: $white !important;
      }
    }
  }

}
