.category-item{
  padding-top: 5px;
  cursor: pointer;
  width: 99%;
  background-color: #cfde4c;
  border-top-left-radius: 20px;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

.category{
  color: black;
  font-weight: bold;
  text-align: center;
  margin-right: 15px;
  display: flex;
  border: 1px solid gray;
  border-radius: 5px;
  border-top-left-radius: 20px;
}

.chip{
  display: inline-flex;
  flex-direction: row;
  background-color: #e5e5e5;
  border: none;
  cursor: default;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  font-color: #333333;
  font-family:"Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  margin-right: 15px;
}

@media (max-width: 480px) {
  .chip{
    margin-top: 15px;
  }
}

.chip-head{
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #32C5D2;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 36px;
  color: #fff;
  height: 36px;
  font-size: 20px;
  margin-right: -4px;
}
.chip-content{
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
.chip-svg{
  color: #999999;
  cursor: pointer;
  height: auto;
  margin: 0px 4px 0 -8px;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}

.chip-add{
  transform: rotate(45deg);
  color: #49ca5b;
  margin-left: 5px;
  font-size: 35px;

  @media (max-width: 480px) {
    margin-top: 20px;
  }
}

.chip-svg:hover{
  color: #666666;
}
