.demo-base {
  text-align: center;
  height: 100%;
}

.demo-base > * {
  margin: 25px auto;
  justify-content: center;
}

.demo-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.blur-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  backdrop-filter: blur(5px) brightness(80%);
}

.demo-backdrop {
  pointer-events: none;
  z-index: 0;
}
