.alert-kolo-card {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alert-kolo-icon {
  height: 110px;
  width: 110px;
  margin-bottom: 30px;
}

.alert-kolo-title {
  color: #2f3542;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 25px;
}

.alert-kolo-subtitle {
  color: #636363;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.kolo-backdrop {
  background-color: rgba(0, 0, 0, 0.411);
}
