@import '../../settings/app.colors';

.menu-icon {
  min-width: 32px;
  min-height: 32px;
  max-width: 34px;
  max-height: 34px;
}

.hideNav {
  transition: all linear 0.7s;
  top: 50px;
  margin-right: -258px;
  //@media screen and (max-width: 480px) {
  //  margin-right: -300px;
  //  z-index: 1020;
  //}
  //@media screen and (max-width: 768px) {
  //  z-index: 1020;
  //}
}

.showNav {
  transition: all linear 0.7s;
  //@media screen and (max-width: 768px) {
  //  margin-left: -300px;
  //  z-index: 1020;
  //}
  //
  //@media screen and (max-width: 480px) {
  //  margin-left: -300px;
  //  z-index: 1020;
  //}
  //
  //@media screen and (max-width: 320px) {
  //  margin-left: -300px;
  //  z-index: 1020;
  //}
}

.remove {
  display: none;
}

aside#slide_panel {
  color: rgba(255, 255, 255, 0.8);
  width: 58px;
  transition: all 0.5s;
  position: fixed;
  left: 0px;
  top: 50px;
  z-index: 2;
  height: 100%;
  //box-shadow: -4px 5px 30px 0px rgba(50, 50, 50, 0.69);
  box-shadow: 5px 0px 25px 0 rgba(179, 179, 179, 0.3);

  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(34, 96, 149, 0.8);
  }

  .fixed_wrap {
    width: 300px;
    //position: fixed;
  }
  .menu-fixed {
    top: 60px;
    transition: all 0.5s;
    //position: fixed;
    width: 50%;
    max-width: 320px;
    //z-index: 1000;
  }

  .nav_aside {
    h3 {
      color: #fff;
      font-family: Montserrat, serif;
      text-transform: uppercase;
      text-align: center;
      padding: 10px 0;
      margin: 10px 10px 10px 40px;
    }
    ul.nav_l1 {
      padding-left: 0;
      li {
        display: flex;
        flex-direction: column;

        &.activo {
          & > a {
            color: $blue1 !important;
          }
        }
        a {
          border-bottom: solid 1px rgba(255, 255, 255, 0.1);
          color: $grey;
          font-size: 1rem;
          font-family: Open Sans, sans-serif;
          padding: 10px 0 0 17px;
          width: 100%;
          text-decoration: none;
          transition: all 0.5s;

          &:hover {
            background: $blue3;
            color: $blue1 !important;
          }
        }
      }
    }
  }

  .activo {
    background: $blue3;
    border-left: solid 2px $blue1;
  }

  .show_sub_nav {
    height: 300px !important;
    overflow: auto;
  }

  @media screen and (max-width: 480px) {
    width: 0;
  }
}

.handler {
  width: 1000px !important;
  background-color: red;
}

.expand {
  width: 100% !important;
}

.rotate {
  transform: rotate(180deg);
}

.my-modal {
  //@media (min-width: 600px) {
  //  width: 70vw;    /* Occupy the 70% of the screen width */
  //  max-width: 70vw;
  //}
}

.my-modal-body {
  overflow: auto;

  //@media (min-width: 600px) {
  //  height: 60vw;
  //  max-height: 60vh;
  //}
}

.my-modal-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  color: $blue1;
  overflow-y: auto;
  flex: 1 1 auto;
}

.videos-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.mi-title {
  font-size: 24px;
  color: #282c34;
  margin-left: 10px;
}

.pv-container {
  display: flex;
  flex-direction: column;
}

.mi-content {
  flex: 1 1 auto;
  overflow: auto;
  min-height: 30vh;
  max-height: 70vh;
}

.mi-description {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  color: #545459;
  margin-left: 20px;
  margin-right: 20px;
}

.mi-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 310fr));
  grid-gap: 10px;
  @media only screen and (max-width: 480px) {
    grid-template-columns: auto;
  }
}

.mi-video-item {
  background-color: ghostwhite;
  position: relative;
  display: flex;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 4px;
  transition: 0.3s all;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  flex-direction: column;
}

.mi-video-title {
  color: #000000;
  margin-top: 10px;
}

.mi-video-description {
  color: #545459;
}

.sidebar-companies {
  display: flex;
  flex-direction: column;

  transition: all 1s;
  width: 19%;
  border-right: 1px solid lightgray;
  overflow: hidden;
}

.sidebar-company {
  height: 150px;
  transition: height 0.5s ease-in-out;

  position: absolute;
  top: 0;
  left: -45px;
}

.sidebar-company.collapsed {
  height: 32px;
}

.sidebar-company-info {
  transition: opacity 0.25s ease-in-out;
}

.sidebar-company-info.collapsed {
  opacity: 0;
}

.company-default-icon {
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
}

.company-default-icon.collapsed {
  opacity: 1;
}

.sidebar-companies-list {
  padding-bottom: 50px;
  overflow: hidden scroll;

  &::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(34, 96, 149, 0.8);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.sidebar-companies-collapse {
  transition: all 1s;
  width: 0;
}

.sidebar-modules {
  transition: all 1s;
  width: 81%;
  overflow: hidden scroll;
  height: 100%;

  &::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(34, 96, 149, 0.8);
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.sidebar-modules-collapse {
  transition: all 1s;
  width: 100%;
}

.sidebar {
  color: rgba(255, 255, 255, 0.8);
  width: 54px;
  transition: all 0.8s;
  position: fixed;
  left: 0;
  top: 50px;
  z-index: 2;
  height: calc(100% - 50px);
  overflow-x: hidden;
  cursor: default;

  &::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(34, 96, 149, 0.8);
  }

  .activo {
    background: #f0f0f0;
    border-left: solid 2px $blue1;
    border-bottom: 2px solid #f0f0f0;
  }

  .show_sub_nav {
    height: 300px !important;
    overflow: auto;
  }

  @media screen and (max-width: 480px) {
    width: 0;
  }
}

.navigation {
  //border-bottom: 1px solid hsla(0,0%,100%,.1);
  color: #208ade;
  font-size: 1rem;
  font-family: Open Sans, sans-serif;
  //padding: 10px 0 0 17px;
  width: 100%;
  text-decoration: none;
  transition: all 0.5s;
  font-weight: 500;
}

.module-detail {
  padding-bottom: 5px;
  color: #208ade;
  background-color: #f4f4f4;
}

.module-detail-selected {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 15px;
  background: #f0f0f0;
  right: -10px;
  transform: rotate(45deg);
  box-shadow: 2px 0px 2px 0px #e4e1e1f0;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.company-logo {
  border-radius: 5px;
  transition: border 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.side-options {
  width: 325px;
  height: 100%;
  background: #ffffff;
  box-shadow: 5px 0px 25px 0 rgba(179, 179, 179, 0.3);
  overflow-y: hidden;
  overflow-x: hidden;

  & ul li {
    cursor: pointer;
  }
}

.circular {
  object-fit: cover;
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
}
