
.DayPicker{
  font-size: 14px !important;
  z-index: 15;
}

.DayPickerInput-OverlayWrapper {
  z-index: 5000;
  // left: 0;
  position: absolute !important;

}