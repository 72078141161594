.paragraph {
  color: #2f3542;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: -0.09px;
  word-break: break-word;
  margin: 0;
}

.bold {
  font-weight: 700;
}

.dim {
  color: #74787c;
}

.italic {
  font-style: italic;
}

.small {
  font-size: 12px;
  line-height: 10px;
}

.medium {
  font-size: 14px;
  line-height: 18px;
}

.big {
  font-size: 16px;
  line-height: 20px;
}

.extraBig {
  font-size: 20px;
  line-height: 22px;
}

.title {
  font-size: 80px;
  font-weight: 600;
  letter-spacing: -4px;
  line-height: 1.6;

    @media (max-width: 635px) {

      font-size: 45px;
      font-weight: 600;
      letter-spacing: -2px;
      line-height: 1.6;
    }
}

.upper-case {
  text-transform: uppercase;
}