@import "../../../settings/app.colors";

.red-button{
  border-width: 0px;
  background-color: $red !important;
}

.red-text{
  color: $red;
  font-size: large;
}

.text-center{
  text-align: center;
}

.active
{
  background-color: $green !important;
  color : white !important;
}

.invoice{
  border: solid;
  border-width: thin;
  border-radius: 10px;
  border-top: dotted;
  padding-bottom: 20px;
  border-color: #d6d6d6;
}
