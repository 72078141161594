.plan-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  overflow-y: auto;
  gap: 15px 10px;
  padding: 5px;
}

.plan-icon {
  justify-content: center;
  margin: 0;
}

.checkout-base h1 {
  font-weight: 900;
  color: #2f3542;
  font-size: 24px;
  line-height: 29px;
  margin-left: 5px;
  margin-bottom: 15px;
}

.checkout-base h2 {
  font-weight: 900;
  color: #2f3542;
  font-size: 22px;
  margin-left: 5px;
  line-height: 20px;
  margin-bottom: 0;
}
