.main-list-group {
    padding: 0; margin: 0;
    overflow-y: auto;
    min-height: auto; max-height: 270px;
  
    /*border-radius: 10px;
    border: thin solid #d6d6d6;
    border-top-style: dotted;*/
  
    &li {
      padding: 0;
      list-style: none;
    }
  }