@import "./../../settings/app.colors";

.gallery-delete {
  background-color: $red;
  color: white;
  text-align: center;
  fontWeight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.gallery-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
  align-items: center;
}

.image-gallery-right-nav{
  font-size: 2.5em;
}

.image-gallery-left-nav{
  font-size: 2.5em;
}

.item-status{
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 18px;
  font-weight: bold;
}

.item-status.green{
  background-color: #2c7;
  color: white;
}

.item-status.red{
  background-color: $red;
  color: white;
}
