@import "../../settings/app.colors";


.map-container{
  width: 100%;
}

.map-address{
  font-weight: bold;
  font-size: 14px;
  color: $blue1;
  padding:10px;
  margin-bottom: 5px;
}