@import 'src/settings/app.colors';

.dashboard-welcome {
  text-align: left !important;
  //color: $blue1;
  //margin-bottom: 15px;
  //font-weight: 500;

  color: #2F3542 !important;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
}

.dashboard-companies {
  margin-top: 30px;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.25fr));

  align-items: center;

  @media (max-width: 480px) {
    grid-template-columns: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}