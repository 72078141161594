.table-search-container {
  margin: 30px 30px 10px 30px;
}

.table-search {
  box-sizing: border-box;
  border: 1px solid #EAECF2;
  border-radius: 6px;
  background-color: #FAFAFA;
}

.empty-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 37px;
  color: #C4C4C4;
}

.table-title {
  color: #2F3542;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  align-items: center;
  text-transform: uppercase;
  border-bottom: 1px solid #EAECF2;
  @media only screen and (max-width: 1024px) {
    border-bottom: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.table-option {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  a {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
  }
}

.table-row-mobile {
  border-bottom: 1px solid #DEE2E6;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.table-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.text-left{
  text-align: left !important;
}

.text-right{
  text-align: right !important;
}

.hide-select{
  display: block;

  @media (max-width: 480px) {
    display: none;
  }
}

.style-quantity{
  max-width: 275px;
  min-width: 250px;
}

.large-t-transaction {
  max-width: 450px;
  min-width: 500px;
}

.medium-t-transaction {
  max-width: 275px;
  min-width: 350px;
}

.small-t-transaction{
  max-width: 200px;
  min-width: 190px;
}

.mini-t-transaction{
  max-width: 100px;
  min-width: 100px;
}

.large {
  min-width: 1200px;
}

