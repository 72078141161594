@import "../../settings/app.colors";

.charts-card-body{
  max-height: 450px;
  overflow-y: scroll;
}

.red{
  color: red;
}

.green{
  color: green;
}

.tab-content{
  color: #73879C;
  background-color: white;
}

.b-user-item {
  background: #f1f4f9;
  border-radius: 5px;
  margin: 4px;

  padding: 4px 8px;

  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.b-item-content {
  background: #f1f4f9;
  border-radius: 5px;

  display: flex;
  cursor: pointer;
}

.b-item-content-size {
  margin: 4px;
  padding: 4px 8px;
  width: 100%;
}

.b-user-item-v2 {
  background: #f1f4f9;
  border-radius: 5px;
  margin: 4px;

  display: flex;
  cursor: pointer;
}


.b-item {
  background: #f1f4f9;
  border-radius: 5px;
  margin: 4px;

  padding: 4px 8px;

  /*display: flex;*/
  display: grid;
  /*justify-content: space-between;*/
  cursor: pointer;

  &.no-background {
    background: none;
  }

  &.no-padding {
    padding: 0;
  }
}

.b-item-title {
  font-size: 18px;
  letter-spacing: -0.09px;
  line-height: 18px;
  font-weight: 500;
  text-transform: capitalize;
  word-break: break-word;
}

.b-user-name {
  // color: #2F3542;
  font-size: 14px;
  letter-spacing: -0.09px;
  line-height: 18px;
  font-weight: 500;
  text-transform: capitalize;
  word-break: break-word;

  &.no-transform {
    text-transform: none;
  }

  &.white {
    color: #FFFFFF;
  }
}

.b-user-name.active{
  color: $white !important;
  background-color: transparent !important;
}

.b-user-name-v2.active{
  color: $white !important;
  background-color: transparent !important;
}

.b-user-email {
  // color: #545459;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 18px;
  word-break: break-word;

  &.white {
    color: #FFFFFF;
  }

  &.red {
    color: red;
  }
}

.b-user-email.active{
  color: $white !important;
  background-color: transparent !important;
}

.b-user-balance {
  margin-right: 4px;
  color: #2F3542;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.09px;
  line-height: 18px;
  @media (max-width: 480px) {
    display: none;
  }
}

.b-user-balance.active{
  color: $white !important;
  background-color: transparent !important;
}

.b-user-balance-m {
  display: none;
  @media (max-width: 480px) {
    display: inline;
    margin-right: 4px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 18px;
  }
}

@media (max-width: 480px) {
  .b-user-balance-m.active{
    color: $white !important;
    background-color: transparent !important;
  }
}

.dropdown-menu a:hover{
  background: #226095!important;
  color: #FFFFFF !important;
}

.bu-en {
  flex: 2;
}

.bu-a {
  flex: 1;
}

.bu-b {
  flex: 1;
  justify-content: flex-end;
}

.bu-c {
  flex: 1;
  justify-content: space-between;
}

.fa-1_5x {
  font-size: 1.5em;
}