@import "../../settings/app.colors.scss";

.text-center {
  text-align: center !important;
}

.indented-td{
  border-left: solid;
  border-left-color: white;
  border-left-width: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin: 25px 0 0 0;
  padding-bottom: 10px;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
  cursor: pointer;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #1b4060;
  background-color: #eeeeee;
  border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #2c689c;
  border-color: #2c689c;
  cursor: default;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

tr {

  &.header {
    border-bottom: 1px solid $black;
    color: $black;
    text-align: center;

    & > th {
      padding-bottom: 10px;
    }
  }

  &.data {
    color: $dark-gray;
    cursor: pointer;
    border-bottom: 1px solid #EAECF2;
    &:nth-child(even) {
      background-color: $light_grey
    }

    & > td {
      padding: 15px 15px;

      &.name {
        text-align: left;
        @media only screen and (min-width: 600px) {
          max-width: 400px;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }

      &.inherit{
        overflow: inherit !important;
        color: black;
      }

      &.right {
        text-align: right;
      }

      &.center {
        text-align: center;
      }

      &.mini {
        @media only screen and (min-width: 600px) {
          width: 30px;
        }
      }

      &.mini0 {
        padding: 0px;
        @media only screen and (min-width: 600px) {
          width: 30px;
        }
      }

      &.short {
        @media only screen and (min-width: 600px) {
          width: 120px;
        }
      }

      &.medium {
        @media only screen and (min-width: 600px) {
          width: 150px;
        }
      }

      &:hover {
        background-color: $blue1 !important;
        color: $white !important;

        &>div>div>a{
          color: $white !important;
        }
      }
    }
  }

  &.empty{
    color: white;
    cursor: pointer;

    & > td{
      padding: 15px 15px;

      &.name {
        text-align: left;
        @media only screen and (min-width: 600px) {
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.btn-group {
  white-space: nowrap;
}
.btn-group .btn {
  float: none;
  display: inline-block;
}
.btn + .dropdown-toggle {
  margin-left: -4px;
}

.filter-menu{
  display: block;
}

.filter-list{
  display: none;
}

@media (max-width: 767px) {
  .filter-menu{
    display: none;
  }
  .filter-list{
    display: block;
  }
}

.list{
  list-style:none;
  text-align: left;
  padding: 2px !important;
}

/*Si falla algo, borrar de aqui hacia abajo xd*/

.t-red {
  background-color: $red !important;
  color : white !important;
}

.t-blue {
  background-color: $kolo_blue !important;
  color : white !important;
}

.t-pink {
  background-color: $kolo_pink !important;
  color : white !important;
}

.t-green {
  background-color: $kolo_green !important;
  color : white !important;
}

.t-gray {
  background-color: $grey !important;
  color : white !important;
}

tr {
  &.data:hover {
    background-color: $blue1 !important;
    color: $white !important;
  }
  &.data {
    & > td {
      &:hover {
        background-color: $blue1 !important;
        color: $white !important;
      }
    }
  }
}


.scrollX {
  overflow-x: auto;
  min-height: 450px;
}

.scroll-x-without-height {
  overflow-x: auto;
  min-height: 0;
}

.DayPickerInput-Overlay{
  color: black;
}

.table-mobile-selected{
  background-color: #046394 !important;
}

.text-white{
  color: white !important;
}

// EXPERIMENTAL
.tableFixHead {
  overflow: auto;
  min-height: 450px;
  max-height: 500px;
  width: 100%;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.tableFixHead tbody th {
  position: sticky;
  left: 0;
}

/* Just common table stuff. Really. */
.tf-table  { border-collapse: collapse; width: 100%; }
.tf-th, .tf-td {
  // padding: 8px 16px;
  white-space: nowrap;
}