@import '../../../settings/app.colors';

.custom-icon-input {
  position: absolute;
  right: 10px;
  top: 34px;
}

.left {
  left: auto;
}

.input {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 68px;

  &.has-error {
    border-color: $red;
  }
}

.input-error {
  color: $red;
  margin-right: 40px;
}

.input-focus {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0;
  color: $blue2;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0 0 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0 0 70px 25px;
    opacity: 0;
  }
}

.input-symbol {
  font-size: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  &.has-error {
    padding-bottom: 15px;
  }
}

.input:focus + .input-focus + .input-symbol {
  color: $blue2;
  padding-left: 28px;
}

.ftf-form-label {
  margin-bottom: 0;
  color: #545459;
  font-size: 13px;
  letter-spacing: -0.31px;
  line-height: 22px;
}
.ft-max {
  position: absolute;
  right: 3px;
}
.ft-max-success {
  color: #87bb87;
}
.ft-max-error {
  color: red;
}

.ft-sub {
  font-size: 10px;
}

.ftf-form-label {
  margin-bottom: 0;
  color: #545459;
  font-size: 13px;
  letter-spacing: -0.31px;
  line-height: 22px;
}