@import "../../../settings/app.colors";

.custom-card {
  width: 100%;
  min-height: 55px;
  background-color: rgba(227, 238, 238, 0.42);
}

.picture-client {
  border-radius: 50%;
  border: 3px solid $blue1;
  display: block;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 15px;
}
