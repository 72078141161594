.account-title {
  font-weight: 700;
  margin-right: 10px;
}

.account-content {
  color: #525252;
  font-size: 14px;
}

.account-legend {
  color: #226095;
  font-weight: 600;
  text-align: right;
  align-self: center;
}

.account-action {
  cursor: pointer;
  color: #226095;
  font-weight: 600;
  text-align: right;
  align-self: center;
}

.account-add-logo {
  border: 1px solid rgb(232, 232, 232);
  border-radius: 5px;
  //width: 175px;
  height: 205px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.img-kolo-text {
  color: #838383;
  font-size: 12px;
  letter-spacing: -0.07px;
  line-height: 18px;
  text-align: center;
}

.ik-delete {
  height: 22px;
  width: 22px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(188, 188, 188, 0.5);
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.delete-h {
  width: 22px;
  height: 22px;
}
