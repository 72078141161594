.manuals {
  margin: 30px 0;
}

.manuals-title {
  color: #323232;
}

.manuals-appcards {
  margin: 15px 0;

  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.manuals-appcards > div {
  width: 230px;
}

@media (max-width: 768px){
  .manuals-appcards {
    gap: 20px;
  }
  .manuals-appcards > div {
    width: calc(50% - 10px);
  }
}

@media (max-width: 360px){
  .manuals-appcards {
    gap: 20px 0;
  }
  .manuals-appcards > div {
    width: 100%;
  }
}
