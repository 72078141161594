.manufacture-container {
  width: 100%;
  height: 350px;
  margin-bottom: 17px;
  position: relative;
  border: 2px solid #226095;
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.manufacture-head {
  position: absolute;
  top: 0;
  height: 60px;
  width: 100%;

  padding: 6px 10px 10px 10px;
  margin: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.manufacture-body {
  position: absolute;
  top: 60px;
  bottom: 35px;
  width: 100%;
  padding-top: 5px;
  overflow: auto;
}

.manufacture-body::-webkit-scrollbar{
  width: 5px;
}

.manufacture-footer {
  position: absolute;
  bottom: 0;
  height: 35px;
  width: 100%;

  padding: 1px 10px 10px 10px;
  margin: 0;
  background-color: rgba(0,0,0,.03);
  border-top: 1px solid rgba(0,0,0,.125);
}