@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700);

.input-container{
  padding: 20px
}

.large-button {
  width: 100%;
}

.login-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.logo-v2 {
  margin-top: 200px;
  margin-left: 115px;

  width: 374px;
  height: 79.97px;
}

.text-login {
  margin-top: 102px;
  margin-bottom: 22px;
  margin-left: 115px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;

  text-align: justify;
  letter-spacing: 0.03em;

  color: #22205F;
}

.input-login {
  margin-left: 115px;
  margin-right: 115px;
  margin-top: 36px;

  height: 63px;
  max-width: 375px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 26px;

  padding-left: 20px;
  padding-right: 20px;
  text-align: justify;
  color: #22205F;

  background-color: #FFFFFF;
  border: 2px solid #D5D5E3;
  box-sizing: border-box;
  border-radius: 5px;
}

.forget-password {
  margin-left: 115px;
  margin-right: 115px;
  margin-top: 20px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  text-decoration-line: underline;

  color: #8E8EA8;

  &:hover {
    color: #0C4CA2;
    cursor: pointer;
  }
}

.login {
  margin-left: 115px;
  margin-right: 115px;
  margin-top: 68px;
}

.button {
  min-width: 192px;
  min-height: 50px;

  background: #27D086;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  border: white;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02,1.02);
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    transform: scale(1,1);
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
    background-color: #cccccc;
  }

  &:focus {
    outline:0;
  }
}

.back-gradient-login {
  display: flex;
  flex-direction: column;
  flex: 2;
  //justify-content: center;
  //align-items: center;
  background: linear-gradient(135deg, #01197C, #0C4CA2);
}

.text-description {
  //position: absolute;
  //left: 49.22%;
  //right: 12.92%;
  //top: 28.43%;
  //bottom: 41.57%;

  max-width: 727px;
  margin-top: 307px;
  margin-left: 247px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 135%;

  letter-spacing: 0.03em;

  color: #FFFFFF;
}

.text-subtitle {
  width: 501px;
  margin-top: 50px;
  margin-left: 247px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 180%;

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 60px;

  background: #F7F7F9;
}

.footer-text {
  font-family: Montserrat;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;

  color: #8E8EA8;
}