.documents-modules-list {
    margin-top: 30px;
  
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 0.3fr));
  
    align-items: center;
    justify-content: center;
  
    @media only screen and (max-width: 480px) {
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(140px, 0.5fr));
    }
  }