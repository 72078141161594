.h7 {
  color: #1d66a4;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}

.h7:hover{
  color: #1d66a4;
  border-bottom: 1px solid black;
}

.kolo-url{
  align-items: center;
  display: flex;
  justify-content: center;
}

.kolo-url-input{
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 480px) {
  .kolo-url-input{
    margin-top: 5px;
    text-align: center;
    display: inline-block;
  }
}

.kolo-company{
  color: #2F3542;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
}

.kolo-title{
  width: 183px;
  color: #1D66A4;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
}

.kolo-text{
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.kolo-newImage{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 212, 79, 0.72);
  border-radius: 10px;
}

.kolo-newImage:hover{
  transform: scale(1.03, 1.03);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: rgba(20, 212, 79, 0.59);
}

.kolo-filter{
  display: block;
  height: 700px;
  background-color: #cccccc;
}

.kolo-product{
  background-color: #cccccc;
}

.kolo-product-selected{
  background-color: rgba(97, 199, 76, 0.95);
}

.kolo-product:hover, .kolo-product-selected:hover{
  transform: scale(1.03, 1.03);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

@media (max-width: 480px) {
  .kolo-filter{
    display: none;
  }
}
