.container__graph-area {
    max-height: 400px;
    max-width: 800px;
    border: 1px dotted gray;
    z-index: 1;
    overflow: hidden;
}

.container__graph {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    border: 1px solid black;
}

svg:not(:root) {
    overflow: hidden;
}