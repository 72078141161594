@import "../../../settings/app.colors";

.document-request-instructions{
  color: $black;
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
  margin-top: 25px;

  &.justify{
    text-align: justify;
  }
}

.request-cashBox-image{
  margin: 20px;
  max-height: 200px;
}