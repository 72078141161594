#cc {
  transition: 0.5s all;
  overflow: hidden;
  max-height: 50px;
}

.row-height-normal, .row-height-deleted {
  cursor: pointer;
  transition: max-height 0.2s ease-in-out;
  overflow-y: hidden;
}

.order-opacity {
  opacity: 0.2;
}

.order-date-delivery-title {
  color: #2F3542;
  font-size: 10px !important;
  font-weight: 700 !important;
  letter-spacing: 0;
  line-height: 17px;

  margin-top: 20px;

  text-transform: uppercase;
}

.order-date-delivery {
  color: #525252;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;

  text-transform: capitalize;
}

/*Custom*/

.a-in-zoom {
  animation-name: animationInZoom;
}

.dot-normal {
  height: 18px !important;
  width: 18px !important;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  @media only screen and (max-width: 768px) {
    height: 14px !important;
    width: 14px !important;
    margin-top: 2px;
  }
}

.dot-small {
  height: 11px;
  width: 11px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.status-line {
  box-sizing: border-box;
  height: 43px;
  width: 1px;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.heart {
  animation: beat 0.3s infinite alternate;
}

@keyframes beat {
  from {transform:scale(0.8)}
  to {transform:scale(1)}
}

.order-text-small {
  color: #525252;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 15px;
}

.order-date {
  color: #525252;
  font-size: 10px !important;
  letter-spacing: 0;
  line-height: 13px;
}

.arrow-normal {
  transition: all 0.2s linear;
  transform: rotate(0);
  cursor: pointer;
}

.arrow-rotate {
  transition: all 0.2s linear;
  transform: rotate(180deg);
  cursor: pointer;
}

.a-in-opacity {
  animation-name: animationInOpacity;
}

.a-out-opacity {
  animation-name: animationOutOpacity;
}

.a-in-up {
  animation-name: animationInUp;
}

.a-in-down {
  animation-name: animationInDown;
}

.a-out-down {
  animation-name: animationOutDown;
}

.a-in-left {
  animation-name: animationInLeft;
}

.a-out-left {
  animation-name: animationOutLeft;
}

.a-in-right {
  animation-name: animationInRight;
}

.a-out-right {
  animation-name: animationOutRight;
}

.a-in-zoom {
  animation-name: animationInZoom;
}

.a-out-zoom {
  animation-name: animationOutZoom;
}

.a-in-bounce {
  animation-name: animationInBounce;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}

.a-wobble {
  animation-name: animationWobble;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.a-in-up, .a-in-down, .a-out-down, .a-in-left, .a-in-right, .a-out-left, .a-out-right {
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
}

.a-in-opacity, .a-out-opacity, .a-in-zoom, .a-out-zoom {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.a-very-fast {
  animation-duration: 0.3s;
}

.a-fast {
  animation-duration: 0.8s;
}

.a-normal {
  animation-duration: 1s;
}

.a-slow {
  animation-duration: 1.5s;
}

.a-very-slow {
  animation-duration: 2s;
}

@keyframes animationInUp {
  from {opacity: 0; transform: translateY(-300px)}
  to {opacity: 1; transform: translateY(0px)}
}

@keyframes animationInDown {
  from {opacity: 0; transform: translateY(300px)}
  to {opacity: 1; transform: translateY(0px)}
}

@keyframes animationOutDown {
  from {opacity: 1; transform: translateY(0px)}
  to {opacity: 0; transform: translateY(300px)}
}

@keyframes animationInLeft {
  from {opacity: 0; transform: translateX(-300px)}
  to {opacity: 1; transform: translateX(0px)}
}

@keyframes animationOutLeft {
  from {opacity: 1; transform: translateX(0px)}
  to {opacity: 0; transform: translateX(-300px)}
}

@keyframes animationInRight {
  from {opacity: 0; transform: translateX(300px)}
  to {opacity: 1; transform: translateX(0px)}
}

@keyframes animationOutRight {
  from {opacity: 1; transform: translateX(0px)}
  to {opacity: 0; transform: translateX(300px)}
}

@keyframes animationInOpacity {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes animationOutOpacity {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes animationInZoom {
  from {opacity: 0; transform:scale(0)}
  to {opacity: 1; transform:scale(1)}
}

@keyframes animationOutZoom {
  from {opacity: 1; transform:scale(1)}
  to {opacity: 0; transform:scale(0)}
}

@keyframes animationInBounce {
  0%{
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    transform: scale(1.1);
  }
  80%{
    transform: scale(0.89);
  }
  100%{
    transform: scale(1) translate3d(0,0,0);
  }
}

@keyframes animationWobble {
  12% {
    transform: rotate(15deg);
  }
  25% {
    transform: rotate(-30deg);
  }
  37% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
}


