@import "../../../settings/app.colors";

.nospace-margin {
  margin-top: 0px;
}

.spaced {
  margin-left: 10px;
}

tr {

  &.data {

    color: $dark-gray;
    cursor: pointer;
    &:nth-child(even) {
      background-color: $light_grey
    }

    & > td {
      padding: 15px 15px;

      &.name {
        @media only screen and (min-width: 600px) {
          max-width: 400px;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }

      &.inherit{
        overflow: inherit !important;
      }

    }

    &:hover {
      background-color: $blue1;
      color: $white !important;
    }

  }
}


.production-modules-list {
  margin-top: 30px;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0.3fr));

  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 0.5fr));
  }
}

.big-button {
  display: flex;
  justify-content: center;
  background-color: $green;
  color: white;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    transform: scale(1.02, 1.02);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    color: white;
  }
}

.red{
  color: red;
}

.green{
  color: green;
}

.blue{
  color: blue;
}

.badge-image{
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;

  align-self: center;

  width: 100%;
  height: 200px;

  border-top-left-radius: 13px;
  border-top-right-radius: 13px;

  cursor: pointer;
}
