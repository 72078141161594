@import '../settings/app.colors';

.main-content {
  background-color: #f4f4f4;
  min-height: 100vh;

  padding-top: 60px;
  //padding-left: 20px;
  padding-right: 20px;

  padding-bottom: 60px;

  padding-left: 80px;

  //@media only screen and (max-width: 780px){
  //  padding-left: 80px;
  //}

  @media only screen and (max-width: 480px) {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}

.main-content.demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.justify-center {
  text-align: center;
}

.column {
  display: grid;
}
