@import "../../../settings/app.colors";

.clients-table-card {
  margin-top: 20px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
}



.card-header {
  background-color: $blue4;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: $white;
  font-size: 14px;
}

.card-body {
  padding: 15px;
}

.form-label {
  color: $blue1;
  font-size: 14px;
  font-weight: bold;
}

.form-input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $blue1;
  padding: 5px 15px;
}
