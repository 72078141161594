@import "../../../settings/app.colors";

.information{

  min-height: 500px;
}

.bodyCard{
  text-align: center;
  padding-top: 150px;
}
