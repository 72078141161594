@import "../../settings/app.colors";

.fa-50{
  font-size: 40em;
}

.maintenance{
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: center;
  background-color: #226095;
  align-items: center;
}

.maintenance-text{
  color: whitesmoke;
  font-size: 50px;
  font-weight: 800;
}

.pointer {
  background-color: #FFFFFF;
  border-radius: 5px;
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #FFFFFF;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px #FFFFFF;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 #FFFFFF;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #FFFFFF;
    box-shadow: 0 0 0 0 #FFFFFF;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(244,157,22, 0);
    box-shadow: 0 0 0 10px rgba(244,157,22, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
    box-shadow: 0 0 0 0 rgba(244,157,22, 0);
  }
}