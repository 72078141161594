@import "../../../settings/app.colors";

.auth-container {
  display: flex;
  min-height: 100vh;
  background: linear-gradient(to bottom right,
          hsl(235.47, 86.89%, 11.96%) 0%,
          hsl(227.88, 81.45%, 16.27%) 12%,
          hsl(223.82, 78.81%, 19.71%) 22.7%,
          hsl(221.36, 77.29%, 22.45%) 32.4%,
          hsl(219.74, 76.33%, 24.62%) 41%,
          hsl(218.6, 75.66%, 26.38%) 48.8%,
          hsl(217.73, 75.16%, 27.88%) 55.8%,
          hsl(217, 74.75%, 29.26%) 62.1%,
          hsl(216.32, 74.36%, 30.67%) 67.8%,
          hsl(215.61, 73.97%, 32.26%) 73%,
          hsl(214.83, 73.54%, 34.18%) 77.9%,
          hsl(213.96, 73.07%, 36.58%) 82.4%,
          hsl(213.01, 72.55%, 39.61%) 86.8%,
          hsl(211.98, 72.01%, 43.41%) 91.1%,
          hsl(210.92, 71.46%, 48.13%) 95.5%,
          hsl(209.85, 82.98%, 53.92%) 100%
  );
}

.logo {
  width: 100%;
  &.small {
    width: auto;
    height: 100px;
  }
}

.login-card{
  max-width: 360px;
  padding: 20px;
}