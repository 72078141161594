.geocoding-style {
  width: 100%;
  height: 200px
}

.floating-panel {
  position: absolute;
  top: 55px;
  left: 35%;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #999;
  text-align: center;
  font-family: 'Roboto','sans-serif';
  line-height: 30px;
  padding: 5px 5px 5px 10px;
}

/* Ultra small devices (320 px and down) */
@media only screen and (max-width: 359px) {
  .geocoding-style {
    height: 500px
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .geocoding-style {
    height: 500px
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .geocoding-style {
    height: 500px
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .geocoding-style {
    height: 575px
  }
}
