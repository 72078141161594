@import '../../../settings/app.colors';

.dashboard-card {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #bcbcbc;

  height: 90px;
  width: 125px;
  padding: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  transition: 0.2s box-shadow;

  &:hover {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.25);

    .dashboard-option-icon {
      transform: scale(1.05, 1.05);
    }
  }

  //border-radius: 20px;
  //border: 1px solid $blue1;
  //padding: 25px;
  //background-color: $white;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  //transition: all 0.3s ease-in-out;
  //text-align: center;
  //
  //@media only screen and (max-width: 480px) {
  //  padding: 10px;
  //  margin: 10px;
  //}
  //
  //&:hover {
  //  transform: scale(1.02, 1.02);
  //  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  //  cursor: pointer;
  //}
  //
  &.selected {
    border: 2px solid rgb(104, 200, 211);
  }
}

.dashboard-option-name {
  color: #226095;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;

  /*position: absolute;
  bottom: 16px;*/

  //font-size: 18px;
  //margin-top: 30px;
  //font-weight: bold;
  //color: $blue1;
  //text-align: center;
  //
  //@media only screen and (max-width: 480px) {
  //  margin-top: 15px;
  //  margin-bottom: 15px;
  //}
  //
  //&.small{
  //  font-size: 14px;
  //
  //  @media only screen and (max-width: 480px) {
  //    font-size: 12px;
  //  }
  //}
}

.dashboard-option-icon {
  height: 50px;
  width: 50px;
  transition: 0.2s transform;

  //margin-top: -10px;
  //max-height: 100px;
  //
  //&.small{
  //  max-height: 50px;
  //}
  //
  //@media only screen and (max-width: 480px) {
  //  margin-top: 15px;
  //  max-he
}

.invert {
  filter: invert(1);
}

.unread-transform {
  transform: scale(0.7) !important;
  top: 8px !important;
  right: 1px !important;
  display: flex;
  align-items: center;
  height: 35px;
}

@media (max-width: 480px) {
  .unread-transform {
    right: 10px !important;
  }
}

.plan-text {
  font-weight: 900;
  color: #2f3542;
  font-size: 24px;
  line-height: 29px;
  margin-left: 5px;
  margin-bottom: 15px;
}
