.promotor-modules-list {
  margin-top: 30px;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0.3fr));

  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 0.5fr));
  }
}

.columns {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  @media only screen and (max-width: 480px) {
    grid-template-columns: auto;
  }
}

.report-item {
  position: relative;
  display: flex;
  background: white;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 4px;
  transition: 0.3s all;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 100px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;

  &:hover {
    transform: scale(1.02, 1.02);
    cursor: pointer;
  }
}

.report-name {
  color: #226095;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 300px;
  min-width: 200px;
}

.report-icon {
  height: 80px;
}

.report-icon-back {
  position: absolute;
  right: -6px;
  height: 100px;
  bottom: -50px;
  opacity: 0.1;
}

.report-info {
  position: absolute;
  top: 10px;
  right: 10px;
}