.input {
  font-weight: 400;
  font-size: 14px !important;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 3px;
  box-sizing: border-box;
  color: #2F3542;
  letter-spacing: -0.09px;
  line-height: 18px;
  width: 100%;
  &:focus {
    outline: none;
    border: 1px solid #164974;
  }
  &::placeholder {
    color: #8E8EA8;
    opacity: 0.5;
    font-style: italic;
  }
}

.search-input {
  border: 1px solid white;
  padding: 12px 16px 12px 32px !important;
  background-image: url(../../../assets/v2/icon-search.svg);
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 22px 22px;
  text-indent: 25px;
}

.input-error {
  color: red;
  font-size: 12px;
}

.input-alias {
  color: black;
  font-size: 11px;
  text-align: left;
}

.input-valid {
  color: green;
  font-size: 12px;
}
