@import "../../../settings/app.colors";
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,700);
* { box-sizing: border-box; }

.back-subscription-card {
  width: 100%;
  overflow: auto;
  padding: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: 600px) {
    padding: 30px;
  }
}

.subscription-card {
  height: 420px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  //transition: all 0.3s;
  font-family: "Raleway";

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  }

  //.description {
  //
  //  padding: 30px;
  //  //float: left;
  //  //width: 55%;
  //  height: 75%;
  //
  //
  //  //border-left: 2px solid #efefef;
  //
    h1 {
      color: #515151;
      font-weight: 300;
      padding-top: 15px;
      margin: 0;
      font-size: 24px;
      font-weight: 500;
    }
  //
    h2 {
      //color: #515151;
      margin: 0;
      //text-transform: uppercase;
      //font-weight: 500;
    }
  //
    h4 {
      margin: 0;
      //color: #727272;
      //text-transform: uppercase;
      //font-weight: 500;
      //font-size: 12px
    }
  //
    h6 {
      color: #727272;
      font-weight: 300;
    }
  //
    p {
      font-size: 12px;
      line-height: 20px;
      color: #727272;

      margin: 0;
    }
  //
  //}

}

@media only screen and (max-width: 359px) {
  .subscription-card {
    margin: 0px;
  }
}

