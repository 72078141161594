.report-title{
  margin-top: 40px;
  color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: rgba(79,81,82,0.62);
}

.report-download{
  display: block;
  padding: 1px 10px;
  margin-top: 35px;
}

.user-tag {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  margin-bottom: 4px;
  border-radius: 5px;
  border: 1px solid #226095;
  overflow: hidden;
}

.label-user-tag {
  margin-right: 10px;
  padding-top: 7px;
  color: #545459
}

.delete-user-tag {
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #226095;
}

.product-t {
  border: 1px solid #CE5454;
}

.d-product-t {
  background-color: #CE5454;
}

.warehouse-t {
  border: 1px solid #8354ce;
}

.d-warehouse-t {
  background-color: #8354ce;
}

.ru-graph-w {
  @media only screen and (max-width: 480px) {
    display: none !important;
  }
}

.ru-graph-m {
  display: none;
  @media only screen and (max-width: 480px) {
    display: block;
  }
}

.g-title {
  color: #282c34;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 50px;
  margin-bottom: 10px;
  margin-top: 16px;
}

.pb-custom {
  .progress-bar{
    background-color: #B35796;
    border-radius: 10px;
  }
}

.invoice-w {
  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}

.invoice-m {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
