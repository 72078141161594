@import '../../settings/app.colors';

.product-list-card {
  margin-top: 3px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}

.product-label {
  font-weight: bold;
  font-size: 14px;

  &.with-margin {
    margin-top: 15px;
  }
}

.big-button {
  display: flex;
  justify-content: center;
  background-color: $green;
  color: white;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 25px;

  &:hover {
    transform: scale(1.02, 1.02);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    color: white;
    cursor: pointer;
  }
}

.no-bottom-margin {
  margin-bottom: 0;
}

.checkbox-label {
  //white-space: nowrap;
  font-size: 13px;
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  align-items: center;
  padding-top: 3px;
  margin-bottom: 5px;

  & > input {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    position: relative;
    top: -4px;
    *overflow: hidden;
  }

  & label {
    margin: 0;
  }
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  color: $blue1;

  margin-bottom: 25px;
}

.ribbon-position {
  top: 18px !important;
  left: -65px !important;
}
