.c-item {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 4px;

  &.centered {
    min-height: 40px;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &.assigned {
    border: 1px solid #226095;
  }
  &.add {
    border: 1px solid #229544;
    cursor: pointer;
  }
  &.removed {
    border: 1px solid #ce5454;
  }
  &.custom-tag {
    border: 1px solid #b35796;
    align-items: center;
  }
  &.custom-tag-selected {
    border: 2px solid #226095;
    align-items: center;
  }

  &.father {
    height: 38px;
  }
  &.child {
    height: 30px;
    margin-top: 5px;
  }
}

.c-item-button {
  padding-left: 10px;
  padding-right: 10px;

  &.centered {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &.assigned {
    background-color: #226095;
  }
  &.add {
    background-color: #229544;
  }
  &.removed {
    background-color: #ce5454;
  }
  &.father {
    padding-top: 7px;
  }
  &.child {
    padding-top: 5px;
  }
}

.c-item-tag {
  margin-right: 10px;
  color: #545459;

  &.father {
    padding-top: 7px;
  }
  &.child {
    padding-top: 5px;
  }
}

.tag-log {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 2px;
  }
}
