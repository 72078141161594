
.carousel {
  li{
    background-color: #8E8EA8 !important;
  }
  li.active {
    background-color: #0C4CA2 !important;
  }
}

.carousel-item.active {
  background-color: transparent !important;
}

.button-back {
  position: absolute;

  top: 26px;
  left: 10px;

  width: 20px;
  height: 20px;

  display: flex;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  @media (min-width: 600px) {
    top: 30px;
    left: 50px;
    width: 40px;
    height: 40px;
  }
}

.icon-back {

}

.title-back {
  margin-left: 40px;

  @media (min-width: 600px) {
    margin-left: 103px;
  }
}

.product-profile-card {
  //position: absolute;
  //top: 110px;
  //left: 10px;
  //right: 10px;
  //bottom: 67px;

  margin-left: 10px;
  margin-right: 10px;

  margin-bottom: 80px;

  background: #FFFFFF;
  border-radius: 13px;

  animation-name: cardIn;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
}

@keyframes cardIn {
  from {opacity: 0; transform: translateX(300px)}
  to {opacity: 1; transform: translateX(0px)}
}

.product-image-profile {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 40px;
  margin-left: 40px;

  height: 180px;
  width: 60vw;

  align-self: center;

  background: #C4C4C4;
  border-radius: 11px;

  object-fit: cover;

  @media (min-width: 600px) {
    margin-top: 79px;
    margin-bottom: 79px;
    margin-right: 20px;
    margin-left: 20px;

    min-height: 500px;
    width: 80%;
  }
}

.product-title-profile {
  text-align: center;
  width: 100%;
  height: auto;
  @media (min-width: 600px) {
    margin-top: 90px;
    margin-bottom: 16px;
    padding: 0;
    text-align: left;
  }
}

.product-category-profile {
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  @media (min-width: 600px) {
    text-align: left;
    justify-content: flex-start;
    margin: 0;
  }
}

.product-description-profile {
  margin-top: 16px;

  margin-left: 30px;
  margin-right: 30px;


  padding: 15px 0px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  display: block;
  align-items: center;
  text-align: justify;

  color: #000000;

  @media (min-width: 600px) {
    margin-left: 0;
    margin-right: 138px;

  }
}

.product-description-profile:first-letter {
  text-transform: uppercase;
}

.product-price-profile {
  //justify-content: center;
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;

  @media (min-width: 600px) {
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
  }
}

.pp-quantity {
  justify-content: center;
  @media (min-width: 600px) {
    margin-top: 90px;
    justify-content: flex-start;
  }
}

.button-add {
  text-align: center;
  padding: 0;
  margin-bottom: 10px;
  @media (min-width: 600px) {
    text-align: end;
    padding-right: 138px;
    margin-top: 90px;
    margin-bottom: 40px;
  }
}

.modal-product {
  background-color: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;

  .modal-dialog {
    max-width: 70%;
    max-height: 70%;
  }

  .modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.6s;
    background-color: transparent;
  }
}

@keyframes zoom {
  from {transform:scale(0) translateY(-500px);}
  to {transform:scale(1) translateY(0px);}
}