.b-item-select {
  border-radius: 6px;
  margin: 4px;
  margin-top: 2px;
  padding: 10px 8px;
  background: #f1f4f9;
  display: grid;
  cursor: pointer;
  &.no-background {
    background: none;
  }
  &.no-padding {
    padding: 0;
  }
}
  
.b-item-select-title {
  font-size: 18px;
  letter-spacing: -0.09px;
  line-height: 18px;
  font-weight: 500;
  text-transform: capitalize;
  word-break: break-word;
}

.b-categories-select {
  // color: #2F3542;
  font-size: 13px;
  letter-spacing: -0.15px;
  
}
.t-item-select-img {
  width: 90px;
  height: 88px;
  border-radius: 5px;
  background-color: #8cd4f5;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  @media only screen and (max-width: 589px) {
    height: 45px;
    width: 45px;
  }
}