@import "../../settings/app.colors";

.stretched{
  height: calc(100% - 25px);
}

h6.filter-title {
  font-weight: bold;
}

.filter-title {
  font-weight: bold;
  color: $black;
  font-size: 14px;
  margin-left: 5px;
}

.filter-description {
  color: $black;
  font-size: 14px;
}

.transfers-subheader {
  margin-left: 0;
  @media only screen and (min-width: 600px) {
    height: 43px;
  }
}

.info-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 25px;
  padding-left: 5px;
  font-size: 18px;
}

.info-section {
  justify-content: center;
}

.transfer-input {
  margin: 0 20px 0 0 !important;
  height: 22px;

  &.input-wrapper {
    width: 45% !important;
  }

  & > input {
    height: 22px;
    text-align: right;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 8px;
  }
}

.external
{
  background-color: $kolo_pink !important;
  color : white !important;
}

tr.external {
  &:hover {
    background-color: $blue1 !important;
    color: $white !important;
  }
  &.data.external {
    & > td {
      &:hover {
        background-color: $blue1 !important;
        color: $white !important;
      }
    }
  }

}

.request {
  background-color: $kolo_blue !important;
  color : white !important;
}

tr.request {
  &:hover {
    background-color: $blue1 !important;
    color: $white !important;
  }
  &.data.request {
    & > td {
      &:hover {
        background-color: $blue1 !important;
        color: $white !important;
      }
    }
  }
}

.process {
  background-color: $grey !important;
  color : white !important;
}

tr.request {
  &:hover {
    background-color: $grey !important;
    color: $white !important;
  }
  &.data.request {
    & > td {
      &:hover {
        background-color: $grey !important;
        color: $white !important;
      }
    }
  }
}
