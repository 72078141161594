.om-name {
  color: black;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.om-address {
  color: #34495E;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.om-status {
  margin-top: 8px;
  color: #226095;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.om-pop-close {
  position: absolute;
  right: 10px;
  top: 17px
}
.fila{
  width: 100%;
}
.columna{
  width: 16.66%;
}

.small-button
{
  margin-right: 20px;
  max-height: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.quantity-orders{
  border: 2px #fff solid;
  border-radius: 60px;
  align-self: center;
  text-align: center;
  min-height: 25px;
  min-width: 25px;
}

.quantity-request{
  border: 2px #fff solid;
  border-radius: 60px;
  align-self: center;
  text-align: center;
  min-height: 20px;
  min-width: 20px;
  font-size: 12px;
}

.badge-tab, .badge-tab-white {
  background-color: #226095;
  color: white;
  border-radius: 20px;
  min-height: 20px;
  min-width: 20px;

  margin-left: 6px;
  margin-right: 6px;

  font-size: 12px;
  //font-weight: bold;

  justify-content: center;
  align-items: center;

  display: flex;
}

.badge-tab-white {
  background-color: white;
}

.tabs-img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-left: 8px;
  margin-right: 8px;
}
