.invoice-page {
}

.invoice-border {
  border: 2px solid #226095;
  border-radius: 5px;
  position: relative;

  background-color: white;

  transform: translateX(-10px);
}

.invoice-title {
  color: #2f3542;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;

  margin-top: 10px;
}

.invoice-text {
  color: #2f3542;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.invoice-logo {
  align-self: center;
  margin-top: 10px;

  height: 100px;
  max-width: 200px;

  object-fit: contain;
  text-align: left;

  //object-fit: none;
  ////object-position: 50% 50%; /* default value: image is centered*/
  //object-position: 0 0;
}

.fel-data-container {
  //text-align: center;

  background-color: white;
  border: 2px solid #226095;
  border-radius: 5px;
  margin: 16px;

  padding-bottom: 10px;
}

.invoice-fel {
  min-height: 30px;
  background-color: #226095;
  border: 2px solid #226095;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  display: flex;
  align-items: center;

  color: white;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
}

.invoice-subtitle,
.invoice-subtitle-c,
.invoice-text-c {
  color: #2f3542;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
}

.invoice-subtitle-c {
  text-transform: uppercase;
  line-height: 10px;
}

.invoice-text-c {
  font-weight: 500;
  line-height: 10px;
}

.invoice-subtitle-t {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
}

.invoice-detail-t {
  color: #2f3542;
  padding-left: 10px;
  padding-right: 10px;
}

.invoice-logo-bg {
  position: absolute;
  opacity: 0.1;
  right: 0;
  bottom: 0;

  height: 300px;
  width: 300px;
}

.invoice-total {
  //max-width: 200px;
  display: flex;

  align-self: flex-end;

  justify-content: center;
  align-items: center;

  //border: 2px solid #226095;
  border-radius: 5px;

  padding-left: 20px;
  //padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.invoice-total-title {
  color: #2f3542;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 34px;
}

.invoice-total-text {
  color: #2f3542;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: 600;

  margin-left: 8px;
}

.invoice-total-words {
  color: #2f3542;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: capitalize;

  text-align: end;
  padding: 0;
}

.company-data {
  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.client-data {
  margin-left: 0;
  @media only screen and (min-width: 480px) {
    margin-top: -30px;
  }
}

.special-invoice {
  border: 1px solid rgb(34, 96, 149);
  width: 150px;
  font-size: 15px;
  background: #226095;
  color: white;
  line-height: 1;
  text-align: center;
  height: 100%;
  padding: 10.5px 0;

  @media (max-width: 480px) {
    padding: 18.5px 0;
  }
}

@media print {
  .company-data, .invoice-document-type {
    max-width: 50%;
  }
}
