

.tf-th {
}


.tf-m {
  min-width: 50px;
  max-width: 50px;
}

.tf-l {
  min-width: 40px;
  max-width: 70px;
}

.static {
  position: absolute;
  background-color: white;
}
