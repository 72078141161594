.card-empty {
  min-height: 354px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-image {
  height: 56px;
  width: 56px;
  margin: 19px;
}

.empty-title {
  color: #2F3542;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 16px;
}

.empty-subtitle {
  color: #636363;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.btn-empty {
  height: 38px;
  width: 209px;
  margin: 26px;
}

.self-center{
  align-self: center;
}
