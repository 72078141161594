.welcome-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 15;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
  animation-name: animationInOpacity;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  transition: all 0.3s;
}

.welcome-card {
  position: relative;
  background-color: #226095;

  //background-color: #6CAFC7;

  border-radius: 5px;
  padding: 20px;

  min-width: 700px;

  min-height: 400px;

  overflow: hidden;

  background-repeat: no-repeat;
  background-position: 90px 40px;
  background-size: 300px;

  //background-image: linear-gradient(to right, rgba(109, 179, 242, .6), rgba(109, 179, 242, .6)), url(../../assets/back-face.png);

  //background-image: url(../../assets/back-face.png);

  @media only screen and (max-width: 480px) {
    height: 90%;
    width: 90%;
    min-width: 0px;
  }
}

.b-shape {
  white-space: pre-line;

  margin-top: 20px;

  opacity: 0;
  //position: absolute;

  //bottom: 30px;


  //right: 0px;
  //left: 0px;

  //margin-left: -100px;

  //width: 400px;
  //height: 50px;
  ////background-image: linear-gradient(to right, #6CAFC7, #6CAFC7), url(../../assets/back-face.png);
  ////background-image: linear-gradient(to right, rgba(249, 179, 242, 1), rgba(1249, 179, 242, 1));
  //
  //
  //background-color: #6caec5;
  //
  //background-repeat: no-repeat;
  //background-position: 170px -30px;
  //background-size: 200px;
  //
  //background-image: url(../../assets/back-face.png);
  //
  //
  //z-index: 0;
  ////left: 0px;
  //right: 0;
  //bottom: 40px;
  //
  ////border-top-right-radius: 5px;
  ////border-bottom-right-radius: 5px;

  height: 80px;
  width: 200px;
  border-radius: 5px;
  font-size: 16px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 90px 24px;
  background-size: 124px;

  background-color: #6CAFC7;
  background-image: url(../../assets/back-face.png);

  //transform: translateX(300px);
  animation: downIn 500ms ease-in-out forwards 3000ms;

  //transition: all 0.3s ease-in-out !important;
  //&:hover {
  //  transform: scale(1.2,1.2) !important;
  //}

  @media only screen and (max-width: 480px) {
    top: 350px;
    animation: rightIn 1000ms ease-in-out forwards 3000ms;

    //right: 0;
  }

}


.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mw-logo {
  margin-top: 20px;
  margin-bottom: 20px;
  animation-delay: 2000ms;
  opacity: 0;

  width: 140px;

  animation: opacityIn 1000ms ease-in-out forwards 1000ms
}

.mw-people {
  height: 300px;
  width: 400px;
  object-fit: contain;
  margin-bottom: -14px;

  animation: downIn 1000ms ease-in-out forwards 1000ms;
  align-self: start;
  margin-left: 50px;

  opacity: 0;


  @media only screen and (max-width: 480px) {
    position: absolute;

    bottom: 0;
    height: 200px;
    width: 300px;
    align-self: center;
    margin-left: 0;
  }
}

.mw-text-1 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  font-family: Gordita;
  z-index: 1;

  @media only screen and (max-width: 480px) {
    font-size: 32px;
  }
}

.mw-button-play {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;

  margin-top: -30px;
  margin-left: -30px;

  cursor: pointer;
  transition: 0.3s all;

  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.mw-play {
  width: 60px;
  height: 60px;
}

.mw-video {
  //height: 500px;
  height: 300px;
  @media only screen and (max-width: 480px) {
    min-height: 200px;
    width: 100%;
  }
}

.mw-text-2 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  color: white;
  opacity: 0;

  text-align: center;

  animation: opacityIn 3000ms linear forwards 2000ms,
  reveal 3000ms ease-in-out forwards 2000ms,
  glow 5000ms linear infinite 5000ms;

  @media only screen and (max-width: 480px) {
    font-size: 24px;
    animation: opacityIn 3000ms linear forwards 2000ms,
    glow 5000ms linear infinite 5000ms;
  }
}

@keyframes opacityIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes downIn {
  from {opacity: 0; transform: translateY(300px)}
  to {opacity: 1; transform: translateY(0px)}
}

@keyframes rightIn {
  from {opacity: 0; transform: translateX(300px)}
  to {opacity: 1; transform: translateX(0px)}
}

@keyframes reveal {
  80%{
    letter-spacing: 3px;
  }
}

@keyframes glow {
  40% {
    text-shadow: 0 0 15px #fff;
  }
}
