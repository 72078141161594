@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,700);
* { box-sizing: border-box; }

.e-card {

  height: 300px;
  background: white;
  margin: 0 auto;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  transition: all 0.3s;
  font-family: "Raleway";

  &:hover {

    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);

  }

  .photo {

    width: 45%;
    height: 300px;
    text-align: center;
    float: left;

    img {
      max-width: 100%;
      height: 300px;
    }
  }

  .description {

    padding: 30px;
    float: left;
    width: 55%;
    border-left: 2px solid #efefef;

    h1 {
      color: #515151;
      font-weight: 300;
      padding-top: 15px;
      margin: 0;
      font-size: 20px;
      font-weight: 300;
    }

    h2 {
      color: #515151;
      margin: 0;
      text-transform: uppercase;
      font-weight: 500;
    }

    h4 {
      margin: 0;
      color: #727272;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px
    }

    p {
      font-size: 12px;
      line-height: 20px;
      color: #727272;
      padding: 20px 0;
      margin: 0;
    }

    button {
      outline: 0;
      border: 0;
      background: none;
      border: 1px solid #d9d9d9;
      padding: 8px 0px;
      margin-bottom: 30px;
      color: #515151;
      text-transform: uppercase;
      width: 125px;
      font-family: inherit;
      margin-right: 5px;
      transition: all 0.3s ease;
      font-weight: 500;

      &:hover {

        // background: darken(white, 2%);
        border: 1px solid #aedaa6;
        color: #aedaa6;
        cursor: pointer;

      }

    }

  }

}

@media only screen and (max-width: 359px) {
  .e-card {
    margin: 5px;
  }
}
