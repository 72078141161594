.selector-wrapper{
  display: flex;
  flex-direction: row;
  margin: 0 15px;
}

.css-1pahdxg-control {
  border-color: #226095 !important;
  box-shadow: 0 0 0 0 #226095 !important;
}

.css-1pahdxg-control:hover {
  border-color: #226095 !important;
}