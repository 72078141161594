.tp-title-modal {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #164974;
}

.tp-title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  color: #164974;
}

.tp-1{
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;

  margin-right: 6px;

  color: #232323;
}

.tp-description {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: #8E8EA8;
}

.icon-arrows {
  height: 28px;
  width: 28px;
  align-self: flex-end;
  margin-bottom: 12px;

  @media only screen and (max-width: 768px) {
    height: 24px;
    width: 24px;
    margin-bottom: 12px;
  }
}

.tp-label {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.03em;
  padding-left: 4px;
  padding-right: 4px;
  color: #8E8EA8;
}

.tp-label-2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.03em;
  padding-left: 4px;
  padding-right: 4px;
  color: #1D66A4;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }

}

.tp-button-add {
  width: 26px;
  height: 26px;

  border-radius: 50%;

  border: 2px solid #1D66A4;
  box-sizing: border-box;

  margin: 11px 11px 13px;

  transition: all 0.3s ease-in-out;

  justify-content: center;
  align-content: center;
  display: flex;

  align-self: flex-end;


  &:hover {
    transform: scale(1.02,1.02);
    border: 2px solid #22205F;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  }
}

.tp-divider {
  border-top: 1px solid #EEEEEE;
  margin: 10px;
}