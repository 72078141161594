@import "../../../settings/app.colors";

.custom-card-subscription {
  width: 100%;
  min-height: 55px;
  background-color: rgba(227, 238, 238, 0.42);
}


.preview-subscription-card {
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  //transition: all 0.3s;
  font-family: "Raleway";

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.sub-style {
  font-family: "Raleway";

  h1 {
    color: #515151;
    font-weight: 300;
    padding-top: 15px;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
  }
  h2 {
    margin: 0;
  }
  h4 {
    margin: 0;
    //color: #727272;
    //text-transform: uppercase;
    //font-weight: 500;
    //font-size: 12px
  }
  h6 {
    color: #727272;
    font-weight: 300;
    margin: 0;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    color: #727272;

    margin: 0;
  }
}

.picture-client {
  border-radius: 50%;
  border: 3px solid $blue1;
  display: block;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 15px;
}

.my-modal-subscription-clients {
  @media (min-width: 600px) {
    width: 50vw;    /* Occupy the 60% of the screen width */
    max-width: 50vw;
  }
}

.my-modal-subscription.periodicity {
  @media (min-width: 600px) {
    width: 30vw;    /* Occupy the 60% of the screen width */
    max-width: 30vw;
  }
}

.my-modal-subscription-products {
  @media (min-width: 600px) {
    width: 90vw;    /* Occupy the 60% of the screen width */
    max-width: 90vw;
  }
}

.products-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.arrow-right {
  box-shadow: 0 0 3px 2px rgba(0,0,0,0.1);
  height: 100px;
  left: -50px;
  position: absolute;
  top: -50px;
  width: 100px;

  text-align: center;

  -webkit-transform: rotate(-45deg);
}

.header-subs {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.arrow-text {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 5px;
  color: white;
  font-size: 14px;
}

.Btn-active.active {
  background-color: #18a2b8 !important;
}