.close-detail{
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #e03c3c;
  border-radius: 5px;
  width: 20px;
  text-align: center;
}

.mb-0{
  margin-bottom: 0px !important;
}

.custom-quill{
  .ql-container.ql-snow{
    border: 1px solid #ececec;
  }
}

li.active {
  z-index: 2;
}
