#contenedor {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.hiddenFabs {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOut;
}

.showFabs {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fabKolo {
  height: 50px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: '#12A8BB';
  color: white;
  border-radius: 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  margin-top: 5px;
  
  margin-bottom: 10px;
  transition: all 0.2s ease-out;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 1rem;
}

.btn-icon {
  width: 50px;
}

.btn-icon:hover {
  width: max-content;
}
.btn-icon:hover span {
  display: flex;
}
.fabKolo:hover {
  box-shadow: 2px 2px 8px 0px #00000044;
  border: 1px solid rgb(60, 60, 77);
  cursor: pointer;
}

.hidden {
  display: none;
}
