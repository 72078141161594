
.recipe-items-container {
  display: flex;
  flex-direction: column;
  height: 45vh;
}

.recipe-items-main {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.manufacture-recipe-container {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}