.tag-container {
  min-width: 100px;
  max-width: 150px;
}

.tag-input {
  min-width: 75px;
  max-width: 107px;
  margin-right: 7px;
  border: none;
}

.tag-container-comment {
  min-width: 300px;
  max-width: 350px;
  height: 34px !important;
}

.tag-input-comment {
  min-width: 275px;
  max-width: 347px;
  margin-right: 7px;
  border: none;
}

.list-tags {
  display: flex;
  overflow: auto;
}

.list-tag-child {
  min-width: 100px;
  max-width: 150px;
}

.list-tag-child-comment {
  min-width: 300px;
  max-width: 350px;
}

.tag-popover {
  width: 175px;
}

.tag-popover-header {
  height: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
  font-size: 1rem;
}

.tag-popover-body {
  min-height: 150px;
  max-height: 200px;
  overflow: auto;
}
