$blue1: #3570A3;
$blue2: #008dc3;
$blue3: #E4F6FD;
$blue4: #017BFF;

$red: #ed462d;

$grey: #A1A6AA;
$light_grey: #F1F4F9;
$presale: rgba(255,188,84,0.56);

$white: #ffffff;
$black: #000000;

$green: rgba(53, 112, 163, 0.7);

$kolo_blue: #226095;
$kolo_pink: #B35796;
$kolo_green: #22b573;

.kolo_blue{background-color: $kolo_blue !important;}
.kolo_pink{background-color: $kolo_pink;}
.kolo_green{background-color: $kolo_green}
.kolo_gray{background-color: $grey}
.kolo_red{background-color: $red}

.purchased{background-color: rgba(29,160,47,0.8);}
.presale{background-color: $presale}

$dark-gray: #525252;
$blue-kolo: #226095;
