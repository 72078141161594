.manual-videos {
  height: calc(100vh - 120px);

  display: grid;
  grid-template-columns: 4fr 8fr;

  gap: 25px;
}

.manual-videos-info,
.manual-videos-info-card,
.manual-videos-list {
  height: 100%;
}

.manual-videos-info {
  padding: 0;
}

.manual-videos-info-body {
  padding: 20px;
}

.manual-videos-header {
  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 220px;
  padding: 20px;

  box-shadow: inset 0px -30px 70px #000a;
}

.manual-videos-title {
  position: absolute;
  bottom: 10px;
}

.manual-videos-title * {
  color: white;
  font-size: 35px;
  line-height: 40px;
}

.manual-videos-list {
  overflow-y: scroll;
  padding-right: 20px;
}

.manual-videos-list .video {
  padding: 0;

  display: flex;
  align-items: center;
}

.manual-videos-list .video-info {
  width: 100%;
  padding: 20px;
}

.manual-videos-list iframe {
  aspect-ratio: 16/9;
}

.manual-videos-empty {
  padding: 20px;
}

@media (max-width: 992px) {
  .manual-videos {
    grid-template-columns: 5fr 7fr;
  }

  .manual-videos-list .video {
    flex-direction: column;
  }

  .manual-videos-list iframe {
    flex-shrink: 1;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .manual-videos {
    height: 100%;
    grid-template-columns: 1fr;
  }

  .manual-videos-list {
    padding: 0;
    overflow-y: hidden;
  }

  .manual-videos-list .video {
    flex-direction: column;
  }
}
