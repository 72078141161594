@import "../../../settings/app.colors";
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,700);
* { box-sizing: border-box; }

.offer-subscription-card {
  width: 100%;
  padding: 10px;
  margin-bottom: 27px;
  padding-bottom: 60px;
  overflow: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.sub-style {
  font-family: "Raleway";

  h1 {
    color: #515151;
    font-weight: 300;
    padding-top: 15px;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
  }
  h2 {
    margin: 0;
  }
  h4 {
    margin: 0;
    //color: #727272;
    //text-transform: uppercase;
    //font-weight: 500;
    //font-size: 12px
  }
  h6 {
    color: #727272;
    font-weight: 300;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    color: #727272;

    margin: 0;
  }
}

@media only screen and (max-width: 359px) {
  .offer-subscription-card {
    margin: 0px;
  }
}