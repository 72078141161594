@import "../../settings/app.colors";

.dashboard-container {
  min-height: 100vh;
  width: calc(100% + 300px);

  display: block;
  overflow-x: hidden !important;

  transition: transform 0.5s ease-in-out;
  transform: translateX(-300px);
}

.dashboard-name{
  color: $white;
  text-align: center;
  font-size: 20px;
}

.dashboard-header {
  padding:20px;
  max-height: 350px;
  background-color: $blue1;
  overflow-x: hidden!important;


  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0;

  .dashboard-logo { grid-area: 1 / 1 / 2 / 2; }
  .dashboard-avatar { grid-area: 2 / 1 / 3 / 2; }
  .dashboard-name { grid-area: 3 / 1 / 4 / 2; }
  .dashboard-header-content { grid-area: 1 / 2 / 4 / 6; }
  .dashboard-menu { grid-area: 1 / 1 / 2 / 2 }

  @media only screen and (max-width: 480px) {

    max-height: 80px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    .dashboard-avatar { display:none }
    .dashboard-name { display: none }
    .dashboard-header-content { display: none }
    .dashboard-menu { grid-area: 1 / 1 / 2 / 2 }
  }
}

.dashboard-logo{
  max-height: 45px;
  @media only screen and (max-width: 480px) {
    max-height: 35px;
    justify-self: start;
  }
}

.dashboard-avatar{
  height: 150px;

  margin: 10px auto;
  padding: 25px;

  border-radius: 75px;
  border: 1px dashed $white;
}

.dashboard-section{
  width: 300px;
  height: 300px;
  background-color: $blue3;

  margin-top: -70px;
  margin-left: 800px;
}

.dashboard-menu {
  font-size: 24px;
  color: $white;
  align-self: center;
}

.menu-expanded {
  transform: translateX(0);
}

.modules-list {
  margin-top: 30px;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 0.3fr));

  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 0.5fr));
  }
}

.dashboard-f-color{
  color: #545459;
}

.dashboard-title{
  font-size: 15px;
  font-weight: 700;
}

.dashboard-subtitle{
  font-size: 12px;
  font-weight: 700;
}

.dashboard-card-blue-body{
  background: #226095;
  color: white !important;
}

.dashboard-currency{
  color: #545459;
  font-weight: 700;
  font-size: 24px;
}

.dashboard-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(214, 213, 213, 0.63);
  z-index: 2;
  display: flex;
}

.dashboard-loading-icon{
  margin: 0 auto;
  align-self: center;

  @media (max-width: 768px) {
    align-self: inherit;
    position: absolute;
    left: 45%;
    top: 25%;
  }

  @media (max-width: 480px) {
    align-self: inherit;
    position: absolute;
    left: 43%;
    top: 15%;
  }
}
