.access-card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s box-shadow ease-in-out;
  border-radius: 8px;
  cursor: pointer;
}

.access-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.access-card-buttons {
  height: 30px;
  padding: 5px 5px 8px;
}

.access-name {
  display: flex;
  align-items: center;

  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0 0 0;
  min-height: 40px;
  border-top: 2px solid #00000003;
}

@media only screen and (max-width: 992px) {
  .access-card > img {
    height: 100px;
  }
}
