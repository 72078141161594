
.pi ul {
  padding: 0;
  margin: 0;
}

.pi ul li {
  list-style: none;
  position: relative;
  padding: 13.5px 3px 12px;
}

.pi-container ul li {
  border-bottom: 1px solid #e8eef1;
}

.pi-container ul li:hover, .pi-container ul li:focus {
  background: #eff6f9;
}

.pi-container .pi-actions {
  position: absolute;
  right: 1px;
  width: 30px;
  top: 35px;
  transform: translateY(-50%);
  text-align: center;
}

.pi-container .pi-src{
  position: absolute;
  left: 1px;
  top: 35px;
  transform: translateY(-50%);
}

.pi-src img {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.pi-container .pi-body {
  margin-left: 53px;
  margin-right: 35px;
  font-size: 15px;
  color: #62748F;
}

.pi-container .pi-more {
  margin-left: 30px;
}

.pi-body-title {
  color: #2f3542;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.pi-body-title h5 {
  font-weight: 600;
  display: inline-block;
  color:#62748F;
  margin: 0 0 7px 0;
  padding: 0;
  left: 5px;
}

.pi-body-title span {
  text-transform: lowercase;
}

.pi-body span {
  float: right;
  color: #62748F;
  font-size: 14px;
}

.pi-container .pi-body p{
  margin: 0;
  padding: 0;
  line-height: 15px;
  font-size: 15px;
  text-align: justify;
}

a:hover {
  text-decoration: none;
}

.pi-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
  z-index: 2;
  background-color: white;
  border-bottom: 1px solid #ebebeb;
}

.pi-scroll {
  overflow-y: auto;
  height: 65vh;
}

.pi-disabled {
  background-color: #d3d3d3;
}

@media only screen and (max-width: 380px) {
  .pi-src {
    visibility: hidden;
  }

  .pi-container .pi-body {
    margin-left: 0;
    margin-right: 35px;
    font-size: 15px;
    color: #62748F;
  }

  .pi-container .pi-more {
    margin-left: 0;
  }
}

@media only screen and (max-height: 1080px) {
  .pi-scroll {
    height: 65vh;
  }
}

@media only screen and (max-height: 800px) {
  .pi-scroll {
    height: 60vh;
  }
}

@media only screen and (max-height: 650px) {
  .pi-scroll {
    height: 55vh;
  }
}

