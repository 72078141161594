@import 'src/settings/app.colors';

.pos-page {
  position: relative;
  display: flex;

  gap: 30px;

  height: 84vh;
  width: 100%;
}

.pos-notifications {
  position: absolute !important;
  height: 100%;
}

.pos-main {
  height: 100%;
  width: calc(100% - 450px);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.pos-items {
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pos-items.full-height {
  height: 100%;
}

.pos-items-title {
  margin: 10px 0;
}

.pos-items-title * {
  margin: 0px;
}

.pos-responsible {
  cursor: default;
}

.pos-items-list {
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

.pos-categories {
  height: 70px;
  padding-bottom: 5px;

  display: flex;
  gap: 5px;

  overflow-x: auto;
  overflow-y: hidden;
}

.pos-category {
  position: relative;
  display: flex;

  background-color: white;

  height: 100%;
  min-width: 100px;
  padding: 10px;

  border: 1px solid transparent;
  border-radius: 10px;

  -webkit-transition: background-color 0.25s, box-shadow 0.25s;
  transition: background-color 0.25s, box-shadow 0.25s;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0px 0px 2px black;
  cursor: pointer;

  &:hover {
    background-color: $kolo_pink !important;
  }

  &.selected {
    background-color: $kolo_pink !important;
    border-color: lightgray;
  }
}

.pos-category-name {
  border-radius: 5px;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 15px;
  letter-spacing: 0;
  text-align: center;
  z-index: 1;
  color: #ffffff;

  @media only screen and (max-width: 320px) {
    font-size: 15px;
    line-height: 12px;
  }
}

.pos-category-img-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  border-radius: 10px;
}

.pos-side {
  height: 100%;
  width: 420px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.pos-bill {
  height: calc(100% - 100px);
  width: 100%;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.pos-bill.full {
  height: 100%;
}

.pos-bill-container {
  height: 100%;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.42);

  display: flex;
  flex-direction: column;
}

.pos-bill-container-modal {
  box-shadow: none;
  padding: 0;
}

.pos-bill-container-modal .pos-bill-body {
  padding-bottom: 20px;
}

.pos-bill-header {
  border-radius: 5px;
  padding: 10px;
  margin: 0;

  font-weight: 600;
  font-size: 14px;
  background: #f1f5fd;
  color: #575757;
}

.pos-bill-header * {
  white-space: nowrap;
}

.pos-bill-body {
  height: 100%;
  padding: 0 10px;
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pos-bill-body > * {
  margin: 0 !important;
}

.pos-bill-footer {
  font-weight: 600;
  font-size: 14px;
  color: #575757;
  background: #f1f5fd;

  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px 10px;

  @media (max-width: 480px) {
    padding: 0;
  }
}

.pos-bill-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.pos-bill-buttons button {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0;
}

.pos-bill-footer-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.pos-bill-footer-buttons button {
  flex: 1;

  margin: 0;
}

.pos-bill-buttons-invoice {
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
}

.pos-buttons-container {
  height: 80px;
}

.pos-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  gap: 10px 8px;

  height: 80px;
}

.pos-buttons button {
  margin: 0;
}

.pos-item-modal {
  overflow: hidden auto;
  min-height: 300px;
  max-height: 680px;

  @media only screen and (max-height: 823px) {
    max-height: 580px;
  }
  @media only screen and (max-height: 720px) {
    max-height: 560px;
  }

  @media only screen and (max-height: 700px) {
    max-height: 480px;
  }

  @media only screen and (max-height: 600px) {
    max-height: 440px;
  }

  @media only screen and (max-height: 568px) {
    max-height: 380px;
  }

  @media only screen and (max-height: 530px) {
    max-height: 300px;
  }
}

.pos-item-modal .b-item {
  padding: 15px 10px;
  margin: 15px 0;

  cursor: default;
}

.pos-item-modal-total {
  font-size: 15px;
  margin: auto;
}

.pos-item-modal .icons {
  display: flex;
  justify-content: flex-end;
  gap: 13px;
}

.pos-item-modal .icons > div {
  width: 14px;
}

.pos-item-modal .icons svg {
  height: auto;
  width: 100% !important;
  aspect-ratio: 1;
}

.pos-item-modal .status-bar {
  margin-top: 5px;
}

.pos-item-modal .status {
  display: flex;
  gap: 5px;
}

.pos-button-circle {
  border: none;
  margin: 0;
  font-size: 20px !important;

  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  color: #ffffff;
}

.pbc-medium {
  height: 35px;
  width: 35px;
  font-size: 18px;
}

.pbc-mini {
  height: 30px;
  width: 30px;
  font-size: 15px;
}

.pre-bill-button-container {
  display: none;
}

@media (max-width: 1000px) {
  .pos-page {
    flex-direction: column;
    gap: 10px;
    height: 85vh;
  }

  .pos-main {
    height: calc(100% - 90px);
    width: 100%;
    gap: 10px;
  }

  .pos-items {
    height: 100%;
  }

  .pos-side {
    height: 100px;
    width: 100%;
  }

  .pos-bill {
    display: none;
  }

  .pre-bill-button-container {
    display: inline;
  }
}

@media (pointer: coarse) {
  .pos-items-list::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
}

.pos-item-principal {
  padding: 5px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding-right: 10px;
  grid-gap: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .pos-item-principal.few-items {
    grid-template-columns: repeat(4, 1fr);
  }
}

.pos-item {
  position: relative;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  overflow: hidden;

  cursor: pointer;
}

.pos-item-img {
  border-radius: 0;
  height: 150px;
  width: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}

.pos-item-data {
  flex-direction: column;
  display: flex;
  height: 100%;
  padding: 10px;
}

.pos-item-component {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.pos-item-label {
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pos-item-name {
  transition: all 0.5s ease-in-out;
}

.pos-item-code {
  color: #636363;
  font-size: 12px;

  margin: 0;
}

.pos-item-price {
  line-height: 15px;
  font-size: 12px;
  color: #2f3542;
  font-weight: 800;
  letter-spacing: 1px;

  margin: 0;
}

@media (max-width: 650px) {
  .pos-item {
    flex-direction: row;
  }

  .pos-item-img {
    height: 130px;
    width: 40%;
  }

  .pos-item-data {
    height: 100%;
    width: 60%;

    flex-direction: column;
    align-items: flex-start;
  }

  .pos-item-component {
    display: flex;
    flex-direction: column;
  }

  .pos-item-price {
    align-self: flex-end;
  }
}

@media (min-width: 1200px) {
  .pos-payments-cards.few-items {
    grid-template-columns: repeat(auto-fit, 250px);
  }
}

.pos-payments-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  height: 100%;
  padding: 10px;
  grid-gap: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  align-content: baseline;
  justify-content: left;
}

.pos-payments-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: 100%;
  aspect-ratio: 1/1;

  background-color: #007ad8;
  color: white;
  font-size: 30px;

  border-radius: 5px;

  cursor: pointer;
}

.pos-payments-card.add {
  background-color: transparent;
  color: #007ad8;
  border: 5px solid #007ad8;
}

.pos-payments-card.add .index {
  color: white;
  font-size: 34px;
  background-color: #007ad8;
  box-shadow: 0 0 0 3px #007ad8;
}

.pos-payments-card.selected {
  background-color: #0066b4;
  box-shadow: 0 0 0 0.35rem #268fff80;
}

.pos-payments-card.paid {
  background-color: #42c229;
}

.pos-payments-card.paid.selected {
  box-shadow: 0 0 0 0.35rem #61e647bd;
}

.pos-payments-card .index {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;

  background-color: rgba(0, 0, 0, 0.1);
  font-size: 40px;
}

.pos-payments-card .amount {
  font-weight: 500;
}

.pos-payments-card .payment-type {
  font-size: 20px;

  position: absolute;
  bottom: 30px;
}

.pos-payments-summary {
  font-size: 17px;
  letter-spacing: -0.09px;
  line-height: 1.3;
  font-weight: 500;
  text-transform: capitalize;
  word-break: break-word;
}

.pos-payments-summary-amount {
  font-weight: 700;
}

.pos-payments-summary-separator {
  height: 3px;
  width: 100%;
  background-color: #73879c;

  margin: 10px 0;
}



.pos-manufacture {
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow: hidden;
}

.pos-manufacture-items {
  height: 100%;
  overflow: hidden auto;
  margin: 0;
}

.pos-tickets {
  gap: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
}

.pos-tickets-cards {
  padding-bottom: 5px;
  height: calc(100% - 175px);
  display: flex;
  gap: 20px;
  overflow-x: auto;
  position: absolute;
  top: 0;
  width: 100%; 
}

.pos-tickets-cards-empty {
  position: relative;
  margin: auto;
}

.pos-tickets-card {
  position: relative;

  flex: 0 0 275px;
  display: flex;
  flex-direction: column;

  height: 100%;

  background-color: transparent;
  border: 5px solid #007ad8;
  color: white;
  font-size: 14px;

  border-radius: 5px;
}

.pos-tickets-card .index {
  flex: 0 0;
  width: 100%;
  text-align: center;

  background-color: #007ad8;
  box-shadow: 0 0 0 3px #007ad8;
  font-size: 20px;
  font-weight: 500;
}

.pos-tickets-card .ticket-content {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px;
  color: #73879c;

  overflow: auto;
}

.ticket-content .ticket-item {
  min-height: 50px;
  padding: 5px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;
}

.ticket-content .ticket-item-content {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.dropend {
  width: 100%;
}

.ticket-content .ticket-item-base:not(:first-child) {
  border-top: 2px solid #3186e79f;
}

.ticket-content .ticket-item-base-empty {
  margin-top: 15px;
}

.ticket-item .ticket-item-name {
  word-wrap: break-word;
}

.ticket-item .ticket-item-price {
  font-weight: 500;
}

.ticket-item .ticket-item-status {
  width: fit-content;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #0001;

  font-size: 11px;
  font-weight: 400;

  display: inline-block;
}

.ticket-menu-base {
  height: auto;
  width: auto;

  position: absolute !important;
  top: 0;
  right: 0;
}

.ticket-menu-icon {
  padding: 0 10px;
}

.ticket-menu.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ticket-menu-item {
  min-width: 100px;
  padding: 10px;
  font-size: 14px;
}

.ticket-menu-item * {
  margin: 0 !important;
}

.ticket-item-menu.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  height: auto;
  width: auto;
  padding: 10px;
}

.ticket-item-menu.show * {
  margin: 0;
}

.ticket-item-menu.show .dropdown-item {
  padding: 0;
}

.ticket-item-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-width: 300px;
}

.ticket-item-menu-item * {
  margin: 0 !important;
}

.ticket-item-menu-item-quantity {
  width: 60px;
  text-align: center;
  -moz-appearance: textfield;
}

.ticket-item-menu-item-quantity::-webkit-outer-spin-button,
.ticket-item-menu-item-quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ticket-item-menu-item-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.ticket-item-menu-item-buttons > * {
  min-width: 20px;
  text-align: center;
}

.pos-tickets-card .ticket-summary {
  flex: 0 0;
  padding: 15px;

  background-color: #007ad8;
  box-shadow: 0 0 0 3px #007ad8;
  font-size: 20px;
  font-weight: 500;
}

.pos-tickets-card.paid {
  border-color: #42c229;
}

.pos-tickets-card.paid .index {
  background-color: #42c229;
  box-shadow: 0 0 0 3px #42c229;
}

.pos-tickets-card.paid .ticket-summary {
  background-color: #42c229;
  box-shadow: 0 0 0 3px #42c229;
}

@media (max-width: 1000px) {
  .pos-tickets-card {
    border: none;
    color: black;

    border-radius: 0;
  }

  .pos-tickets-card .index {
    display: none;
  }

  .pos-tickets-card .ticket-content {
    padding: 0;
  }

  .ticket-content .ticket-item-base:not(:first-child) {
    border-top: 2px solid #cfd5db;
  }

  .pos-tickets-card .ticket-summary {
    color: #73879c;
    background-color: transparent;

    padding: 15px 0;
    border-top: 2px solid #3186e79f;

    box-shadow: none;
  }

  .ticket-item-menu {
    left: -300px !important;
  }
}


.pos-tickets-summaries {
  
  gap: 15px;
  display: flex;
  overflow-x: auto;
  position: absolute;
  bottom: 0;
  width: 100%; 
}

.pos-tickets-summary {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  height: 150px;
  aspect-ratio: 1;

  background-color: #007ad8;
  color: white;
  font-size: 18px;

  border-radius: 5px;

  cursor: pointer;
}

.pos-tickets-summary.add {
  background-color: transparent;
  color: #007ad8;
  border: 5px solid #007ad8;
}

.pos-tickets-summary.add .index {
  color: white;
  background-color: #007ad8;
  box-shadow: 0 0 0 2px #007ad8;
  font-size: 20px;
  padding: 0;
}

.pos-tickets-summary.selected {
  background-color: #0066b4;
  box-shadow: 0 0 0 0.25rem #268fff80;
}

.pos-tickets-summary.paid {
  background-color: #42c229;
}

.pos-tickets-summary.paid.selected {
  box-shadow: 0 0 0 0.25rem #61e647bd;
}

.pos-tickets-summary .index {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;

  background-color: rgba(0, 0, 0, 0.1);
  font-size: 18px;
  padding: 5px 0;
}

.pos-tickets-summary .amount {
  font-weight: 500;
}

@media (max-width: 1000px) {
  .pos-tickets-cards {
    display: none;
  }

  .pos-tickets-summaries {
    flex-basis: 0 0 100%;

    padding: 10px;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 0.2fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-content: baseline;
    justify-content: left;

    overflow-x: hidden;
    overflow-y: auto;

    position: relative;
    bottom: 0;
  }
}

.a-in-opacity {
  animation-name: animationInOpacity;
}

@keyframes animationInOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.a-in-opacity,
.a-out-opacity,
.a-in-zoom,
.a-out-zoom {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.infinity-screen {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.intersect-spinner {
  margin: auto;
}

.pos-tabs-base {
  // display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.pos-tabs-decorator {
  position: sticky;
  bottom: 7px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #007ad8;
}

.pos-tabs {
  position: relative;
  display: flex;
  gap: 5px;

  width: fit-content;
  padding: 0 15px 5px;
  margin-top: 15px;

  scrollbar-color: #f4f4f4;
}

.pos-tabs::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

.pos-tabs.bill {
  padding-left: 0;
  padding-right: 0;
}

.pos-tabs.bill::-webkit-scrollbar-track {
  background-color: white;
}

.pos-tab {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  background-color: #e0e0e0;
  width: 160px;
  height: 35px;
  padding: 0 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: -10px;
  border: 2px solid transparent;
}

.pos-tab:hover {
  background-color: #e5e5e5;
}

.pos-tabs.bill .pos-tab {
  padding: 0 5px;
  width: 100px;
  gap: 5px;
}

.pos-tab span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pos-tab.add-tag {
  justify-content: center;

  width: 40px !important;
  padding: 0;

  cursor: pointer;
}

.pos-tab.add-tag * {
  width: 100%;
}

.pos-tab.selected {
  background-color: transparent;
  border: 2px solid #007ad8;
}

.pos-tab.selected::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #f4f4f4;
  z-index: 1;
}

.pos-tabs.bill .pos-tab.selected::after {
  background-color: white;
}

.pos-tabs-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.pos-tabs.item-modal .pos-tab.selected::after {
  background-color: white;
}

.pos-tabs-base .info-hover {
  position: absolute;
  top: 0;
  right: 0;
}

.pos-tabs.item-modal .pos-tab {
  display: flex;

  width: 110px;
}

.pos-tabs.item-modal {
  margin: 0;
}

.pos-tabs .button-remove {
  font-weight: 600;
  cursor: pointer;
}

.pos-tabs.item-modal .pos-tab.add {
  justify-content: center;

  width: 50px;
  font-size: 25px;
}

.pos-bill-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
}

.pos-product {
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
  cursor: default !important;
}

.pos-product.enabled:hover {
  transform: scale(1.01);
  cursor: pointer !important;
}

.pos-product.enabled:active {
  filter: brightness(0.96);
}

.pos-product .top-bar,
.pos-product-modal .top-bar {
  margin: 5px 0 10px;
}

.pos-product-modal .top-bar {
  display: flex;
  justify-content: space-between;
}

.pos-product .checkbox,
.pos-product .checkbox * {
  margin: 0 !important;
  padding: 0 !important;
}

.pos-product .icons {
  display: flex;
  gap: 13px;
}

.pos-product .icons > div {
  width: 14px;
}

.pos-product .icons svg {
  height: auto;
  width: 100% !important;
  aspect-ratio: 1;
}

.pos-product .status-bar {
  margin-top: 5px;
}

.pos-product .status {
  display: flex;
  gap: 5px;
}

.pos-product .commentary {
  position: relative;

  margin: 10px 0px 5px;
  padding: 25px 5px 5px;
  border-radius: 5px;

  background-color: #2222220a;
}

.pos-product .commentary::before {
  content: 'Comentario';
  font-size: 13px;
  font-weight: 500;

  position: absolute;
  top: 4px;
  width: 100%;
  height: 100%;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(236, 236, 236, 0.479); /* Opacidad de la marca de agua */
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.5); /* Agregar un borde */
  border-top: 4px solid #3498db; /* Color del borde superior */
  animation: spin 1s linear infinite; /* Animación de rotación */
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}