
.custom-add-logo {
  border: 1px solid rgb(232, 232, 232);
  border-radius: 5px;
  // width: 175px;
  min-width: 200px;
  max-width: 350px;
  height: 205px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.custom-img-text {
  color: #838383;
  font-size: 12px;
  letter-spacing: -0.07px;
  line-height: 18px;
  text-align: center;
}

.custom-delete {
  height: 22px;
  width: 22px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(188,188,188,0.5);
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
