@import "../../settings/app.colors";

.notification {
  display: flex;
  position: fixed; 
  z-index: 15;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
}

.notification-container {
  color: white; 
  padding: 20px;
  position: relative; 
  font-family: Arial, sans-serif;
  border-radius: 5px;
}

.notification-title {
  font-weight: bold; 
  margin-bottom: 5px;
  font-size: 30px;
}

.notification-message {
  font-size: 18px; 
  max-width: 780px;
}

.notification-card {
  position: relative;
  background-color: #226095;
  border-radius: 5px;
  padding: 30px;
  overflow: hidden;

  background-repeat: no-repeat;
  background-position: 90px 40px;
  background-size: 300px;

  @media only screen and (max-width: 480px) {
    height: 90%;
    width: 90%;
    min-width: 0px;
  }
}