

.shopping-cart-card {
  margin-bottom: 0px;
  @media (min-width: 600px) {
    margin-bottom: 0px;
  }
}

.header-title {
  width: 100%;
  height: 0;
  overflow: hidden;

  @media (min-width: 600px) {
    top: 0;
    left: 0;
    right: 16px;

    padding-top: 45px;
    height: 66px;
  }
}

.title-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  //align-items: center;
  text-align: justify;
  letter-spacing: 0.035em;
  //text-transform: uppercase;

  color: #232323;

  text-align: center;

  //background-color: orange;

}

.item-text {

  @media (min-width: 600px) {
    margin-top: 24px;
  }
}

.products-cart {
  //overflow-y: scroll;
  margin: 0px;
  padding: 0px;
  //position: absolute;
  top: 66px;
  bottom: 85px;
  left: 0px;
  right: 10px;

  &::-webkit-scrollbar-track
  {
    border-radius: 100px;
    //background-color: transparent;
    background-color: #c4c4c41a;
  }
  &::-webkit-scrollbar
  {
    width: 6px;
    border-radius: 100px;
    //background-color: transparent;
    background-color: #c4c4c41a;
  }
  &::-webkit-scrollbar-thumb
  {
    border-radius: 100px;
    //background-color: #C4C4C4;
    background-color: #8E8EA8;
  }

  @media (min-width: 600px) {
    overflow-y: scroll;
    height: 52vh;
  }
}

.product-item {
  //background-color: goldenrod;
  //
  //height: 100px;

  margin-top: 30px;

  //label {
  //  font-family: Montserrat;
  //  font-style: normal;
  //  font-weight: 400;
  //  font-size: 16px;
  //  line-height: 29px;
  //  text-align: left;
  //  letter-spacing: 0.03em;
  //
  //  color: #22205F;
  //
  //  @media (min-width: 600px) {
  //    font-weight: 600;
  //    font-size: 24px;
  //  }
  //}

  @media (min-width: 600px) {

  }
}

.product-image-cart {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}


.footer-total {
  //position: absolute;
  //
  //bottom: 0;
  //left: 0;
  //right: 0;

  height: 85px;

  background: #1143B6;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}


.footer-text-total {
  margin: 28px 20px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  color: #FFFFFF;

  @media (min-width: 600px) {
    margin: 28px 50px;
  }

}


//label {
//  font-family: Montserrat;
//  font-style: normal;
//  font-weight: 400;
//  font-size: 16px;
//  line-height: 29px;
//  text-align: left;
//  letter-spacing: 0.03em;
//
//  color: #22205F;
//
//  @media (min-width: 600px) {
//    font-weight: 600;
//    font-size: 24px;
//  }
//}

.name-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.03em;

  color: #22205F;



  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }

    @media (min-width: 600px) {
    font-weight: 600;
    font-size: 24px;
    text-align: left;
  }
}

.producer-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;

  //display: flex;
  //align-items: center;
  //text-align: center;

  width: 100%;

  text-transform: capitalize;

  color: #8E8EA8;


  @media (min-width: 600px) {
    text-align: left;
  }
}

.price-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: right;
  letter-spacing: 0.03em;

  color: #22205F;

  @media (min-width: 600px) {
    font-weight: 600;
    font-size: 24px;
  }
}


.quantity-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: right;
  letter-spacing: 0.03em;

  color: #22205F;

  @media (min-width: 600px) {
    font-weight: 600;
    font-size: 24px;
  }
}

.quantity-text:before {
  @media (max-width: 600px) {
    content: "Cantidad: ";
  }
}

.delivery-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  text-align: right;
  letter-spacing: 0.03em;

  color: #22205F;

  @media (min-width: 600px) {
    font-weight: 600;
    font-size: 24px;
  }
}

.delivery-text:before {
  @media (max-width: 600px) {
    content: "Entrega estimada: ";
  }
}

.subtotal-text {

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-align: right;
  letter-spacing: 0.03em;

  color: #22205F;

  @media (min-width: 600px) {
    text-align: center;
    font-size: 24px;
  }
}

.delete-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;
  justify-content: flex-end;

  width: fit-content;

  color: #22205F;

  margin-bottom: 37px;
  margin-right: 40px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  @media (min-width: 600px) {
    margin-right: 65px;
  }
}

.button-quantity-card {
  width: 26px;
  height: 26px;

  border-radius: 50%;

  border: 2px solid #EEEEEE;
  box-sizing: border-box;

  margin: 0 22px;

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02,1.02);
    border: 2px solid #22205F;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  }
}

.button-order {
  justify-content: center;

  margin-bottom: 67px;

  @media (min-width: 600px) {
    justify-content: flex-end;
    margin-bottom: 50px;
  }
}

.no-data {
  padding: 60px;


  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;

  display: flex;

  flex-direction: column;
  //align-items: center;
  text-align: center;
  justify-content: center;

  color: #8E8EA8;
}