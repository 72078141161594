.f-actions {
  width: 100%;
  height: 69px;
  background-color: #E3E3E3;
  position: relative;

  @media only screen and (max-width: 490px) {
    height: 90px;
  }
}

.f-actions-body {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 25%;

  @media only screen and (max-width: 490px) {
    flex-direction: column;
    top: 1%;
  }
}

.f-actions-button {
  order: 1;
  @media only screen and (max-width: 490px) {
    order: 2;
    display: flex;

    .f-button-width {
      flex: 1;
    }
  }
}

.f-actions-total {
  order: 2;
  width: 300px;

  @media only screen and (max-width: 640px) {
    width: 175px;
  }

  @media only screen and (max-width: 490px) {
    order: 1;
    width: 100%;
  }
}

.t-item-img {
  width: 70px;
  height: 88px;
  border-radius: 5px;
  background-color: #8cd4f5;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  @media only screen and (max-width: 589px) {
    height: 45px;
    width: 45px;
  }
}

.t-items-body {
  height: calc(100vh - 300px);
  overflow: auto;
}