@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700);


.content {
  position: relative;
  height: 88vh;
}

.content-body {
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0;
  right: 0;

  height: 150px !important;

    @media (min-width: 600px) {
      top: 100px;
      //bottom: 0px;
      height: auto !important;
    }
}



.button-cart {
  position: absolute;
  top: 10px;
  right: 10px;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1,1.1);
    top: 8px;
    right: 7px;
    cursor: pointer;

    @media (min-width: 600px) {
      top: 98px;
      right: 48px;
    }
  }

  @media (min-width: 600px) {
    top: 100px;
    right: 50px;
  }
}

.icon-cart {
  //position: absolute;
  //top: 6px;
  //right: 14px;
  //
  //@media (min-width: 600px) {
  //  top: 6px;
  //  right: 14px;
  //}
  height: 40px;
  width: 40px;
}

.badge {
  position: absolute;
  right: 20px;
  border: 3px solid #FFFFFF;
  background-color: #EB0000;
}

.pbc-title {
  margin-top: 10px;
  margin-left: 10px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 54px;

  color: #232323;

  @media (min-width: 600px) {
    margin-top: 24px;
    margin-left: 0px;

    font-size: 44px;
  }
}

.input-search {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;

  width: 100%;
  height: 60px;


  margin-top: 20px;
  margin-right: 6px;

  padding-left: 20px;
  padding-right: 20px;
  text-align: justify;
  color: #22205F;

  background-color: #FFFFFF;
  border: 0px solid #FFFFFF;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (min-width: 600px) {
    margin-top: 0;
  }
}

::placeholder {
  color: #8E8EA8;
  font-weight: 200;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #8E8EA8;
  font-weight: 200;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #8E8EA8;
  font-weight: 200;
}

.button-search {
  //background: #C4C4C4;
  background: #8E8EA8;

  position: absolute;
  top: 20px;
  right: 0;

  height: 60px;
  width: 76px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  transition: all 0.3s ease-in-out;

  //&:hover {
  //  transform: scale(1.02,1.02);
  //  box-shadow: 0px 0px 15px 5px rgba(256, 256, 256, 1);
  //}
  //
  &:focus {
    outline:0;
  }

  @media (min-width: 600px) {
    top: 0;
  }
}

.company-profile {
  position: relative;

  //min-height: 630px;

  margin-top: 4px;

  margin-left: 15px;
  margin-right: 15px;

  background: #F9FBFF;
  border-radius: 13px;

  justify-content: center;
  align-items: center;
  align-content: center;

  text-align: center;
}

.company-category {
  margin: 20px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: justify;

  color: #8E8EA8;
}

.company-address {
  margin: 10px 50px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #8E8EA8;

}

.company-description {
  margin: 20px 45px 45px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #8E8EA8;
}

.call {
  background: #8E8EA8;
}

.icon-call {
  margin-top: -4px;
  margin-right: 8px;
}

.filter-header {
  background: #1143B6;
  width: 100%;
  height: 60px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  padding-left: 35px;

  color: #FFFFFF;

  border: 1px solid #1143B6;


  margin-bottom: -22px;

  &:focus {
    outline:0;
  }
}

.filter-content {
  background: #1143B6;
  padding-bottom: 20px;
}

.filters {
  position: relative;

  min-height: 630px;

  margin-top: 4px;

  background: #1143B6;
  border-radius: 13px;

  padding-bottom: 45px;

  @media (min-width: 600px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.title-filter {
  position: absolute;
  margin-top: 20px;
  margin-left: 36px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.arrow-down {
  position: absolute;
  top: 26px;
  right: 36px;

  background-color: transparent;

  &:hover {
    transform: scale(1.2,1.2);
    cursor: pointer;
  }
}

.line {
  border-bottom: 1px solid #FFFFFF;
  //transform: rotate(-180deg);
  height: 60px;
}

.cb-container {
  display: block;
  position: relative;
  padding-left: 54px;
  padding-right: 20px;
  margin-top: 22px;
  //margin-bottom: 11px;
  cursor: pointer;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  //display: flex;
  align-items: center;
  //text-align: justify;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 36px;
  bottom: 0;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  //background-color: #eee;

  border: 1px solid #FFFFFF;
  box-sizing: border-box;
}

.cb-container:hover input ~ .checkmark {
   //background-color: #FFFFFF;
   transform: scale(1.1,1.1);
   box-shadow: 0 0 15px 5px rgba(256, 256, 256, 0.3);
 }

.cb-container:hover input:checked ~ .checkmark {
  //background-color: #FFFFFF;
  transform: scale(1.1,1.1);
  box-shadow: 0 0 15px 5px rgba(256, 256, 256, 0.3);
}

.cb-container input:checked ~ .checkmark {
  //background-color: #FFFFFF;
  transform: scale(1.1,1.1);
  box-shadow: 0 0 5px 1px rgba(256, 256, 256, 0.3);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cb-container input:checked ~ .checkmark:after {
  display: block;
}

.cb-container .checkmark:after {
  left: 3px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.company-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 31px;
  line-height: 38px;

  padding: 20px 25px 0;

  color: #232323;
}

.p-container {
  margin-bottom: 67px;
  width: 100%;

  @media (min-width: 600px) {
    margin: 0;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.products-list {

  margin: 0;
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 0 0 20px;

  &::-webkit-scrollbar-track
  {
    border-radius: 100px;
    //background-color: transparent;
    background-color: #c4c4c41a;
  }
  &::-webkit-scrollbar
  {
    width: 6px;
    border-radius: 100px;
    //background-color: transparent;
    background-color: #c4c4c41a;
  }
  &::-webkit-scrollbar-thumb
  {
    border-radius: 100px;
    //background-color: #C4C4C4;
    background-color: #8E8EA8;
  }

  @media (min-width: 600px) {
    //overflow-y: scroll;
  }
}

.product-quantity-card {
  min-height: 503px;

  background: #FFFFFF;
  border-radius: 13px;

  position: relative;

  flex: auto;

  flex-direction: column;

  justify-content: center;
  align-items: center;
  align-content: center;

  text-align: center;

  transition: all 0.2s ease-in-out;

  margin: 10px 0;

  &:hover {
    transform: scale(1.01,1.01);
    box-shadow: -4px -4px 15px 5px rgba(256, 256, 256, 0.7), 4px 4px 20px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
}

.product-image {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;

  align-self: center;

  background: #C4C4C4;
  width: 100%;
  height: 259px;

  border-top-left-radius: 13px;
  border-top-right-radius: 13px;

  object-fit: cover;
}

.product-new {
  position: absolute;
  width: 111px;
  height: 60px;

  top: 68px;
  left: -26px;

  background: #FFBE2E;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transform: rotate(-90deg);

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase;

  justify-content: center;

  color: #FFFFFF;

}

.product-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.03em;

  //margin-top: 29px;
  padding: 0 20px;

  overflow-y: hidden;

  color: #22205F;

  display: block;

  @media (min-width: 600px) {
    height: 84px;
  }
}

.product-title:first-letter {
  text-transform: uppercase;
}

.product-category {
  //margin: 12px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;

  padding: 0 20px;


  color: #8E8EA8;
}

.product-delivery {
  //margin: 12px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;

  padding: 0 20px;
  margin-bottom: 0;

  color: #8E8EA8;
}

.product-price {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: justify;

  color: #22205F;
}

.divider {
  border-top: 1px solid #EEEEEE;
  margin-left: 61px;
  margin-right: 61px;
}

.button-quantity {

  //display: flex;

  width: 22px;
  height: 22px;

  border-radius: 50%;

  border: 2px solid #22205F;
  box-sizing: border-box;

  margin: 2px;

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02,1.02);
    border: 2px solid #22205F;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  }

  &.disabled {
    border: 2px solid #b6c9bc;
    background-color: rgba(203,200,200,0.3);
  }
}

.icon-quantity {
/*  &.disabled {
    background-color: #b6c9bc;
  }*/
}

.product-quantity {

  min-width: 25px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;

  display: flex;
  align-items: center;
  text-align: justify;
  justify-content: center;

  color: #22205F;
}

//.footer {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  right: 0;
//
//  height: 67px;
//
//  background: #F7F7F9;
//}
//
//.footer-text {
//  font-family: Montserrat;
//  font-style: normal;
//  font-size: 18px;
//  line-height: 22px;
//
//  color: #8E8EA8;
//}

.no-margins {
  margin: 0;
  padding: 0;
}

.center {
  justify-content: center;
  text-align: center;
}

.jc-right {
  display: flex;
  justify-content: flex-end;
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #555;
}