
.border-free {
  border: 3px solid #226095;
}

.border-occupied {
  border: 3px solid #B35796;
}

.background-free {
  background-color: rgba(18, 168, 187, 0.6) !important;
}

.background-occupied {
  background-color: rgba(179, 87, 150, 0.6) !important;
}

.pos-terminal-img {
  width: 100px;
  height: 75px;
}

.pos-terminal {
  position: relative;
}


.pos-terminal-icon {
  position: absolute;
  top: 5px;
  right: 10px;
}