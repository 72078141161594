.debit{
  color:green
}

.credit{
  color:red
}

.header-pink{
  background-color: rgb(179,87,150) !important;
}
