@import '../../settings/app.colors';

nav {
  background: #ffffff;
  //border-bottom: solid 1px #EDEDED;
  box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.3);
  transition: all 0.5s;
  height: 50px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.icon-tb {
  height: 24px;
  width: 24px;
}

#logo {
  //padding: 5px;
  //padding-left: 15px;
}

#logo a {
  display: inline-block;
}

#logo a:hover img {
  opacity: 0.8;
  cursor: pointer;
}

#logo a h1,
#logo a h2 {
  font-size: 10px;
  margin: 0;
  padding: 0;
}

#logo a h2 {
  text-indent: -500px;
}

.toogle {
  margin-left: 16px;
}

.toogle:hover {
  cursor: pointer;
  opacity: 0.8;
}

.icon {
  color: $grey;
}

.toolbar-logo {
  height: 40px;
  //margin-top: 3px;
  margin-left: 4px;
}

/*aqua banner acceso directo crear ordenes crear producto, banners*/
$background-color: #999;
$ribbon-color: hsl(242, 50%, 25%);
$contrast-color: if(
  lightness($ribbon-color) > 50%,
  darken($ribbon-color, 50%),
  lighten($ribbon-color, 50%)
);

$nav-height: 100px;

$tab-width: 100px;

$transition-speed: 200ms;
$ribbon-hover-distance: 1px;

#navDirectAccess > a {
  text-decoration: none;
  color: $contrast-color;
}

#navDirectAccess > span.title-label {
  //Center label within tab
  position: relative;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
}

#navDirectAccess {
  background: none;
  box-shadow: none;
  position: initial;
  margin-right: 20px;

  @media (max-width: 480px) {
    #imgAD {
      margin-top: 0px !important;
    }
  }

  img {
    height: 50px;
  }
  ul {
    // centers the UL vertically
    position: relative;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;

    height: $nav-height/2;

    /* Ribbon sides */
    ////&:before, &:after {
    //  content: "";
    //  position: absolute;
    //  display: block;
    //
    //  //Each triangle should be half the height of the UL (which is half the height of the nav)
    //  //Triangles are rendered using borders
    //  border: $nav-height/4 solid $ribbon-color;
    //}

    &:before {
      //The pseudo-element is actually outside of the UL by its own size
      //so the right edge lines up with the left edge of the UL
      transform: translateX(-100%);

      //The length of the ribbon sides (changing it doesn't look weird)
      border-right-width: 100%;

      //Cuts away the outside triangle
      border-left-color: transparent;
    }

    &:after {
      //Moves the right side to the right
      right: 0;

      //And brings the pseudo-element outside of the UL
      transform: translateX(100%);

      //Length of right ribbon side
      border-left-width: 100%;
      border-right-color: transparent;
    }

    li {
      /*
        - Removes list decoration
        - Helps position pseudo-elements
      */
      display: block;

      //Makes it so that the tabs don't stack
      float: left;

      width: $tab-width;
      height: 100%;
      //background-color: $ribbon-color;

      //Adds the little creases between the tabs
      border-right: 1px dotted mix($contrast-color, $ribbon-color);
      &:first-child {
        border-left: 1px dotted mix($contrast-color, $ribbon-color);
      }

      a {
        //Makes the a fill up the tab (so clicking anywhere in the tab works)
        display: inline-block;
        width: 100%;
        height: $nav-height/2;
        text-align: center;
      }

      &:hover {
        a {
          //background-color: lighten($ribbon-color, 10);

          //Adds a hitbox to prevent odd hover behavior
          //It's actually a pretty large hitbox
          //Good usability in general
          &::before {
            content: '';
            display: block;
            height: 300%;
            width: 100%;
            position: absolute;
            transform: translateY(-50%);
          }
          transition: background-color $transition-speed;
        }
        border-color: transparent;
        transition: transform $transition-speed;

        //Makes the hovered LI get bigger and moves it down
        //.5 * .125 * $nav-height is half the amount the height of the LI grows
        //(It grows 25%, so 12.5% is the amount that will bring it flush to the top after it grows)
        //However, because we're scaling by 1.25, the translation also scales, so we need to scale it
        //  down by 80%
        transform: scale(1.1)
          translateY((0.1 * 0.125 * 0.5 * $nav-height) + $ribbon-hover-distance);
        position: relative;
        z-index: 1;
      }

      /* "ligaments" -- the parts that connect the expanded tab to the rest of the ribbon */
      &::before,
      &::after {
        //transform: translateY(-100%);
        //content: '';
        //position: absolute;
        //display: block;
        //border-style: solid;
        //border-width: 0;
        //opacity: 0;
        //transition: border-width $transition-speed ease;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }

      $ligament-color: darken($ribbon-color, 20);
      &:hover::before {
        border-color: transparent $ligament-color transparent transparent;
        //The triangle fits perfectly to make the shape we want
        //The height is the ribbon hover distance
        //The width is the amount the LI grows divided by two, adjusted by the amount we're scaling by
        border-width: $ribbon-hover-distance $tab-width * 0.125 * 0.8 0 0;
        opacity: 1;
      }

      &:hover::after {
        border-color: transparent transparent transparent $ligament-color;
        border-width: $ribbon-hover-distance 0 0 $tab-width * 0.125 * 0.8;
        opacity: 1;
        position: absolute;
        top: 0;
      }
    }
  }
}

.verify-button {
  line-height: 0;
  padding: 17px;
  background: $blue-kolo !important;
  border: 1px solid $kolo_blue;
  border-radius: 6px;

  &:hover {
    background: #2260952e !important;
    border: 1px solid white;
  }
}

.spinner {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.toolbar-access-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 15px;
}


.dropbtn {
  cursor: pointer;
}

.unread {
  font-family: Arial;
  font-size: 0.7em;
  font-weight: 700;
  position: absolute;
  top: -1px;
  right: -15px;
  padding: 0px 8px;
  padding-top: 3px;
  line-height: 100%;
  border: 2px #fff solid;
  border-radius: 60px;
  background: rgba(219, 59, 60, 0.81);
  transform: scale(0.5);
  color: white;
}

.dropbtn:hover {
  transform: rotate(45deg);
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 1px;
  top: 50px;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
}

.dropdown-content div {
  padding: 5px;
  text-decoration: none;
  z-index: 100;
}

.dropdown-content div:hover {background-color: #ddd}

.show {display:block;}

.config-notifications{
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: whitesmoke;
}

div .kolo_pink:hover{
  background-color: $kolo_pink !important;
}

.kolo_pink div:hover{
  background-color: $kolo_pink !important;
  transform: scale(1.10);
}