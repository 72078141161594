.folder-header{
  color: black !important;
  width: 100%;
  margin: 10px
}

.add-file-div{
  display: flex;
  cursor: pointer;
  color: rgba(78, 62, 62, 0.31);
}

.categorization-add{
  background-color: #18b513;
  padding: 5px;
  margin-left: 15px;
  border-radius: 4px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.item-position{
  width: 20px;
  white-space: nowrap;
  border-bottom: 2px solid #bbbbbb;
  border-left: 2px solid #bbbbbb;
  left: 0%;
  position: absolute;
  color: white;
  height: 18px;
}
