.main-list {
  margin: 0;
  overflow-y: auto;
  max-height: 350px;

  &li {
    padding: 0;
    list-style: none;
  }
}

@media only screen and (min-height: 1024px) {
  .main-list {
    max-height: 450px;
  }
}