.max {
  height: 325px;
  width: 100%;
}

@media only screen and (max-width: 359px) {
  .max {
    height: 160px;
  }
}

