.container-offer > div {
  display: flex;
}

.flex-start-offer {
  justify-content: flex-start;
}

.center-offer {
  justify-content: center;
}

.flex-end-offer {
  justify-content: flex-end;
}

.space-between-offer {
  justify-content: space-between;
}

.space-around-offer {
  justify-content: space-around;
}
