.input-card {
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 42px 26px;
  &.none {
    background-image: url(../../../assets/v2/card-placeholder.png);
    background-size: 42px 42px;
  }
  &.amex {
    background-image: url(../../../assets/v2/card-amex.png);
  }
  &.diners_club_carte_blanche {
    background-image: url(../../../assets/v2/card-diners.png);
    background-size: 42px 35px;
  }
  &.diners_club_international {
    background-image: url(../../../assets/v2/card-diners.png);
    background-size: 42px 35px;
  }
  &.jcb {
    background-image: url(../../../assets/v2/card-jcb.png);
  }
  &.laser {
    background-image: url(../../../assets/v2/card-placeholder.png);
    background-size: 42px 42px;
  }
  &.visa-electron {
    background-image: url(../../../assets/v2/card-visa-electron.png);
  }
  &.visa {
    background-image: url(../../../assets/v2/card-visa.png);
  }
  &.mastercard {
    background-image: url(../../../assets/v2/card-mastercard.png);
  }
  &.maestro {
    background-image: url(../../../assets/v2/card-maestro.png);
  }
  &.discover {
    background-image: url(../../../assets/v2/card-discover.png);
  }
}
