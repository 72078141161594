.w-add-button {
  @media (max-width: 992px) {
    display: none;
  }
}

.w-add-button-m {
  display: none;
  @media (max-width: 992px) {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.key-row {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  flex: 1;
  @media (max-width: 480px) {
    justify-content: space-between;
  }
}

.b-bottom {
  border-bottom: 2.5px solid #EAECF2;
}

.key-title {
  color: #2F3542;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.key-value {
  color: #2F3542;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
  margin-left: 16px;
  margin-right: 16px;
}

.base-sub-items {
  list-style-type: square;
  padding-left: 18px;
}