
.container-holder {
  background: white;
  border: 1px solid #e5e6e9;
  border-radius: 5px;
  max-width: 769px;
  padding: 1rem;
}

.background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-name: preloadAnimation;
  -moz-animation-name: preloadAnimation;
  -o-animation-name: preloadAnimation;
  animation-name: preloadAnimation;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  display: flex;
  min-height: 100px;
}

.right {
  flex: 1;
}

.image {
  width: 120px;
  height: 120px;
  background: transparent;
}

.bar {
  height: 22px;
  background: transparent;
  position: relative;
  width: 100%;
}

.bar::before {
  position: absolute;
  content: "";
  left: 0;
  width: 2rem;
  height: 22px;
  background: white;
}

.bar::after {
  position: absolute;
  right: 0;
  height: 22px;
  content: "";
  background: white;
}

.bar.medium::after {
  width: 50%;
}

.bar.small::after {
  width: 75%;
}

.mask {
  background: white;
  width: 100%;
}

.thick {
  height: 1.5rem;
}

.thin {
  height: 0.5rem;
}

@keyframes preloadAnimation{
  0%{
    background-position: -769px 0
  }
  100%{
    background-position: 769px 0
  }
}
