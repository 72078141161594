.report-title{
  margin-top: 40px;
  color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  background-color: rgba(79,81,82,0.62);
}

.g-color {
  height: 20px;
  width: 20px;
  border: 2px solid lightgray;
  margin-left: 10px;
  margin-right: 10px;
}

.index-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.g-row {
  animation: opacityIn linear 300ms;
}

@keyframes opacityIn {
  from {opacity: 0;}
  to {opacity: 1;}
}