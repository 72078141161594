
.orders-container {
  position: relative;
  display: block;
  overflow: auto;
  transition: 0.3s;
  cursor: pointer;
}

.order-list {
  display: block;
  height: 75px;
  width: 100%;
  margin-bottom: 2px;
}

.order-list:hover {
  background-color: #1b4060;
}

.order-list:hover p{
  color: #fff;
}
.order-list:hover small{
  color: #fff;
}

.order-list:hover span{
  color: #fff;
}

.order-list-avatar {
  height: 100%;
  width: 75px;
  padding: 2px;
  border-radius: 50%;
  float: left;
}

.order-list-content {
  display: block;
  float: left;
  height: 100%;
  padding: 5px;
}

.order-list-content p {
  display: block;
  margin: 0;
}

.order-list-content span {
  font-size: 16px;
}

.order-list-content small {
  display: block;
  font-size: 13px;
}

.order-list-action {
  float: right;
  display: flex;
  height: 100%;
  width: 45px;
  justify-content: center;
  align-items: center;
  //background-color: #1b4060;
}

.order-list-action button {
  display: block;
  position: absolute;
  background-color: #1b4060;
  border: none;
  text-align: center;
  color: #FFFFFF;
  height: 75px;
  text-align: center;
  font-size: 15px;
  width: 25px;
  transition: all 0.5s;
}

.order-list-action button > span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.order-list-action button > span:after {
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.order-list-action:hover button > span {
  padding-left: 7px;
}
.order-list-action:hover button > span:after {
  opacity: 1;
  right: 0;
}

.order-list-divider {
  margin: 0 0 0 75px;
  display: block;
  float: bottom;
}

.order-list-divider hr{
  margin: 0;
  padding: 0;
}

.style-map-route {
  height: 320px;
  width: 100%;
  padding: 1px;
  margin-bottom: 15px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.tr-height {
  height: 80vh;
}

/* Ultra small devices (320 px and down) */
@media only screen and (max-width: 359px) {
  .order-list-avatar {
    display: none;
  }

  .order-list {
    height: 85px;
  }

  .order-list-divider {
    margin: 0;
  }

  .order-list-action {
    width: 19px;
  }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .style-map-route {
    height: 230px;
  }

  .orders-container {
    max-height: 250px;
  }

  .order-list {
    height: 90px;
  }

  .order-list-content {
    width: 200px;
  }

  .order-list-action {
    width: 20px;
  }

  .order-list-action button {
    height: 100px;
    margin-bottom: 120px;
  }

  .order-list-avatar {
    width: 50px;
    height: 50px;
  }

  .order-list-divider {
    margin: 0 0 0 50px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .style-map-route {
    height: 325px;
  }

  .orders-container {
    max-height: 335px;
  }

  .order-list-action {
    width: 25px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .style-map-route {
    height: 325px;
  }

  .orders-container {
    max-height: 350px;
  }

  .order-list-action {
    width: 25px;
  }
}

/* Transition of Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .style-map-route {
    height: 700px;
  }

  .orders-container {
    max-height: 625px;
  }

  .order-list {
    height: 90px;
  }

  .order-list-avatar {
    height: 75px;
  }

  .order-list-content {
    width: 200px;
  }

  .order-list-action {
    width: 25px;
  }

  .order-list-action button {
    height: 90px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1200px) {
  .style-map-route {
    height: 775px;
  }

  .orders-container {
    max-height: 700px;
  }

  .order-list-content {
    width: 300px;
  }

  .order-list-action {
    width: 30px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
  .style-map-route {
    height: 875px;
  }

  .orders-container {
    max-height: 800px;
  }
}
