@import 'src/settings/app.colors';

.picker_file_csv_container {
  display: flex;
}

.picker_file_csv {
  border: 1px solid;
  border-radius: 8px;
  text-transform: uppercase;
  display: flex;
  color: white;
  background-color: $kolo_blue;
  padding: 10px;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.csv-text-w{
  display: block;
  @media (max-width: 480px) {
    display: none;
  }
}

.csv-text-m{
  text-align: center;
  display: block;
  @media (min-width: 480px) {
    display: none;
  }
}
