.commentary-body {
  --ds--code--bg-color: var(--ds-background-neutral, rgba(9, 30, 66, 0.08));
  font-size: 0.875em;
  font-weight: normal;
  background-color: var(--ds--code--bg-color,#F4F5F7);
  border-style: none;
  border-radius: 3px;
  display: inline;
  padding: 2px 0.5ch;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow: auto;
  -webkit-box-decoration-break: clone;
  text-align: justify;
  text-justify: inter-word;
}