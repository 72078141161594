@import "../../../../settings/app.colors";
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,700);
* { box-sizing: border-box; }

.product-card {
  margin: 3px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.3s;
  width: 230px;
  height: 230px;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.01, 1.01);
    //color: white;
  }
}

.product-style {
  font-family: "Raleway";

  h1 {
    color: white;
    text-shadow: 1px 1px #000000aa;
    font-size: 22px;
    font-weight: 500;
  }
  h2 {
    margin: 0;
  }
  h4 {
    margin: 0;
    //color: #727272;
    //text-transform: uppercase;
    //font-weight: 500;
    //font-size: 12px
  }
  h6 {
    color: #727272;
    font-weight: 300;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    color: #727272;
    margin: 0;
  }
}

.back-shadow {
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.gradient {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
}

.products-container {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  //margin-left: 20px;
  //margin-right: 20px;
  padding: 20px;

  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    height: 6px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb
  {
    background-color: #0c5460;
  }
}

.image {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 230px;
  height: 130px;
  object-fit: cover;
}

.container-style {
  position: relative;
  text-align: center;
  color: white;
}

.bottom-left {
  position: absolute;
  bottom: 3px;
  left: 16px;
}
