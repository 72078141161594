.delete-button{
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #CE5454;
  border: 1px solid #CE5454;
  border-radius: 0px 5px 5px 0px;
}

.filter-recipes{
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  border: 1px solid gray;
  border-radius: 5px;
}

@media only screen and (max-width: 480px) {
  .detail-recipe{
    padding: 5% 0 !important;
    margin-bottom: 10px !important;
  }
}