.position-fab-kolo {
  bottom: 24px;
  right: 24px;
}

@media only screen and (max-width: 1199px) {
  .position-fab-kolo {
    bottom: 40px;
    right: 1px;
  }
}

@media only screen and (min-width: 1200px) {
  .position-fab-kolo {
    bottom: 40px;
    right: 45px;
  }
}

#fab-kolo {
  opacity: 1;
  transition: opacity 0.7s;
}

#fab-kolo.hide-fab {
  opacity: 0;
  pointer-events: none;
}
