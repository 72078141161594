.order-summary {
  font-family: 'Arial', sans-serif;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  padding: 16px;
  margin: auto;
}

.order-header {
  border-bottom: 1px solid #e1e4e8;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.order-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

.order-body {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.text-group {
  flex-grow: 1;
  align-items: center;
  max-width: calc(33.333% - 32px);
  flex: 1 1 calc(33.333% - 32px);
}

@media (max-width: 768px) {
  .text-group {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.order-button {
  background-color: #b35796;
  color: rgb(255, 255, 255);
  border: none;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
  padding: 10px 15px;
  white-space: nowrap; /* Asegúrate de que el texto del botón no se envuelva */
}
